.list-group {
  margin-bottom: $line-height-computed;
}
.list-group-item {
  background-color: transparent;
  margin-bottom: 0;
  border-width: 0 0 1px;
  width: 100%;
  @include clearfix;

  > .label {
    float: right;
  }

  .panel > .list-group > & {
    border-bottom-width: 0;
  }

  &.custom-select {
    padding: 10px 15px;
    height: auto;
    font-size: inherit;
    line-height: inherit;
    border-radius: 0;
  }

  &.custom-checkbox,
  &.custom-radio {

    .custom-control-indicator {
      position: relative;
      float: right;
    }
    .custom-control-description {
      margin-top: 0;
      font-weight: normal;
    }
  }

  .close {
    line-height: 1;
  }
}

.list-group-item-heading {
  line-height: 1.2;
}

input.list-group-item {
  outline: 0;

  &:focus {
    border-color: $input-border-focus;
  }
  &:last-child {
    @include border-bottom-radius(0);
  }
}

.form-control.list-group-item,
select.list-group-item {
  padding-left: 10px;
}

a.list-group-item,
button.list-group-item {
  color: $text-color;
  @include hover-focus {
    color: $text-color;
  }
}
.list-group-inverse {
  .list-group-item {
    border-color: rgba(#fff, .2);
  }
  a.list-group-item,
  button.list-group-item {
    color: $gray-lighter;
    @include hover-focus {
      color: #fff;
    }
  }
}
.list-group-header {
  display: block;
  padding: 19px 15px 5px;
  border-bottom: 1px solid $list-group-border;
  color: $text-muted;
  font-size: $element-font-size;
  letter-spacing: .5px;
  font-weight: 500;
  text-transform: uppercase;
}

.block-list {
  margin-bottom: $line-height-computed;

  .panel > &,
  > .list-group {
    margin-bottom: 0;
  }

}
.panel > .block-list > .list-group:last-child > .list-group-item:last-child,
.panel > .list-group > .list-group-item:last-child {
  border-bottom: 0;
}