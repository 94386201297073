// Noted
//
// #

.noted {
  padding-left: .5em;
  color: $text-muted;
  font-style: italic;
  border-left: 3px solid darken($border-color, 2%);
}

.noted-primary {
  border-color: $brand-primary;
}
.noted-info {
  border-color: $brand-info;
}
.noted-success {
  border-color: $brand-success;
}
.noted-warning {
  border-color: $brand-warning;
}
.noted-danger {
  border-color: $brand-danger;
}
