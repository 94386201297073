.close {
  margin-left: .5em;
  font-size: 20px;
  line-height: 1.2;
  @include opacity(.4);

  &:hover,
  &:focus {
    @include opacity(.65);
  }
}

.alert-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 500;
  line-height: $line-height-base;
}

.alert {
  .close {
    color: inherit;
  }

  .alert-link {
    color: inherit;
    font-weight: normal;
    text-decoration: underline;
  }

  &:not(.alert-solid) > p {
    color: $text-color;
  }

  &-icon {
    margin-right: .5em;
  }

  &-arrow {
    border-width: 0 0 0 4px;
  }

  .panel-body > & {
    margin-bottom: ($line-height-computed/2);
  }
}

.alert-banner {
  margin-bottom: 0;
  border-width: 0;
  border-radius: 0;
  text-align: center;

  @media (max-width: $grid-float-breakpoint-max) {
    .alert-indicator-icon,
    .alert-icon {
      display: none;
    }

    &.alert-indicator {
      border-left-width: 1px;
    }
  }
}

.alert-solid {
  &.alert-success {
    @include alert-variant($brand-success, $brand-success, #fff);
  }
  &.alert-info {
    @include alert-variant($brand-info, $brand-info, #fff);
  }
  &.alert-warning {
    @include alert-variant($brand-warning, $brand-warning, #fff);
  }
  &.alert-danger {
    @include alert-variant($brand-danger, $brand-danger, #fff);
  }
}

.alert-indicator {
  position: relative;
  border-left-width: 46px;

  &-icon {
    position: absolute;
    top: 50%;
    left: -46px;
    width: 46px;
    line-height: 20px;
    margin-top: -10px;
    color: #fff;
    text-align: center;
  }
}

.alert-arrow,
.alert-indicator {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 5px;
    border-color: transparent;
    border-left-color: inherit;
  }
}
.alert-arrow {
  &:before {
    top: 20px;
    margin-top: 0;
  }
}