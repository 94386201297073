.media {
  overflow: visible;
}
.media-heading {
  font-size: 18px;
  margin-bottom: ($line-height-computed/2);

  > a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

.media-img {
  width: 46px;
  height: auto;

  &-sm {
    width: 36px;
  }
  &-md {
    width: 64px;
  }
  &-lg {
    width: 80px;
  }
}