.modal-header .close {
  margin-top: 2px;
}

.modal-header {
  text-align: center;
  @include border-top-radius($border-radius)

  &.bg-primary,
  &.bg-info,
  &.bg-success,
  &.bg-warning,
  &.bg-danger,
  &.bg-inverse {
    color: #fff;
  }

  .noted {
    margin-top: ($line-height-computed/2);
    margin-bottom: 0;
  }
}

.modal-footer {
  background-color: $modal-footer-bg;

  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;

    .btn {
      display: block;
      width: 100%;
      margin-bottom: ($line-height-computed/2);

      + .btn {
        margin-left: 0;
      }
    }
  }
}