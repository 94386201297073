.timeline {
  position: relative;
  display: block;
  margin: 0;
  padding: 10px 0;
  @include clearfix();

  &,
  > li {
    list-style: none;
  }

  & > li:nth-child(even) {
    float: left;
    clear: left;
  }

  & > li:nth-child(odd) {
    float: right;
    clear: right;
  }
}

.timeline-line {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  background-color: darken($border-color, 3%);
  @include translate( -50%, 0 );

  + .timeline-item {
    margin-top: -20px;
  }
}

.timeline-group {
  display: block;
  position: relative;
  margin: 20px 0;
  text-align: center;
  float: none !important;
  z-index: 1;
}

.timeline-poster {
  margin-top: -20px;

  .btn-link {
    color: $text-muted;

    &:hover,
    &:focus,
    &:active,
    &.active {
      text-decoration: none;
      color: $text-color;
    }
  }
}

.timeline-item {
  position: relative;
  display: inline-block;
  width: 50%;
  padding: 0 30px 20px;

  &:nth-child(even) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      right: 19px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid darken($border-color, 3%);
      z-index: 1;
    }
    &:after {
      right: 20px;
      top: 11px;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 11px solid #fff;
    }

    > .timeline-badge {
      right: -8px;
    }
  }
  &:nth-child(odd) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 19px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid darken($border-color, 3%);
      z-index: 1;
    }
    &:after {
      left: 20px;
      top: 11px;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 11px solid #fff;
    }

    > .timeline-badge {
      left: -8px;
    }
  }

  &.block:nth-child(even),
  &.block:nth-child(odd) {
    width: 100%;
    margin-top: 5px;

    &:before,
    &:after {
      left: 50%;
      right: auto;
      top: -11px;
      border: none; // normalize first
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid darken($border-color, 3%);
      @include translate( -50%, 0 );
    }
    &:after {
      top: -10px;
      border: none; // normalize first
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid #fff;
    }

    > .timeline-badge {
      top: -28px;
      left: 50%;
      right: auto;
      @include translate( -50%, 0 );
    }
  }

  > .timeline-badge {
    position: absolute;
    top: 16px;
    z-index: 1;

    > a {
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 4px solid $brand-primary;
      border-radius: 24px;
      background-color: #fff;
      text-decoration: none;
      @include transition( all ease .3s );

      &:hover,
      &:focus,
      &:active,
      &.active {
        @include scale(1.5);
      }
    }
  }

  > .timeline-panel {

    > .timeline-resume {
      padding-top: 0;
      border: none;
    }

    &:hover {
      .timeline-actions {
        display: block;
      }
    }
  }
}

.timeline-actions {
  display: none;
}


// screen md max
@media (max-width: $screen-md-max)  {
  .timeline {
    padding-left: $spacer;
  }
  .timeline-line {
    left: $spacer;
  }
  .timeline-group {
    display: inline-block;
    margin-left: 4px;
  }

  .timeline-item {
    width: 100%;
    margin-top: 0 !important;
    padding-right: 10px;

    &.block:nth-child(even),
    &.block:nth-child(odd) {
      padding-bottom: 0;
    }

    &:nth-child(even),
    &:nth-child(odd),
    &.block:nth-child(even),
    &.block:nth-child(odd) {
      &:before,
      &:after {
        left: 19px;
        top: 10px;
        border: none;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid darken($border-color, 3%);
        @include translate( 0, 0 );
      }
      &:after {
        left: 20px;
        top: 11px;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 11px solid #fff;
      }

      > .timeline-badge {
        top: 12px;
        left: -6px;
        right: auto;
        @include translate( 0, 0 );
      }
    }
  }
}


// screen xs
@media (max-width: $screen-xs-max)  {
  .timeline {
    padding: 0;
  }
  .timeline-line {
    display: none;
  }
  .timeline-item {
    display: block;
    padding: 0 0 20px !important;

    &:nth-child(even),
    &:nth-child(odd),
    &.block:nth-child(even),
    &.block:nth-child(odd) {
      float: none;
      clear: both;
      &:before,
      &:after {
        display: none;
      }
    }

    &.timeline-poster {
      > .timeline-badge {
        display: none;
      }
    }

    > .timeline-badge {
      top: -8px !important;
      left: 50% !important;
      margin-left: -16px;
      > a {
        height: 1px;
        width: 32px;
        border-width: 3px;
      }
    }
  }
}

.form-timeline-poster {
  font-size: 1rem;

  &:focus {
    box-shadow: none;
  }
}