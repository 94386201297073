$progress-line-color:          tint($gray-light, 65%) !default;
$progress-indicator-color:     tint($gray-light, 65%) !default;
$progress-indicator-border:    $body-bg !default;

@mixin progress-list-state($background, $color: #fff) {
  .bg-inverse &:before,
  .bg-inverse &:after,
  &:before,
  &:after {
    background-color: $background;
  }
  &.has-error > button,
  &.has-completed > button {
    border-color: $progress-indicator-border;
  }
  > button {
    color: $background;
    background-color: $color;
    border-color: $background;
    .bg-faded & {
      border-color: $brand-default;
    }
    .bg-inverse & {
      border-color: $brand-inverse;
    }
  }
}

.progress-list-wrapper {
  position: relative;
  margin-bottom: $line-height-computed;

  .progress-list {
    margin-bottom: 0;

    @media (min-width: $screen-sm-min) {
      margin: 0 auto;
      // max-width: 70%;
    }
  }
}

.progress-list {
  position: relative;
  margin-bottom: $line-height-computed;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  display: table;
  table-layout: fixed;

  > li {
    position: relative;
    display: table-cell;
    width: 1%;
    text-align: center;
    vertical-align: middle;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 50%;
      height: 3px;
      background-color: $progress-line-color;
    }
    .bg-inverse & {
      &:before,
      &:after {
        background-color: $gray;
      }
    }
    &:after {
      left: 50%;
    }

    &:first-child:before {
      display: none;
    }
    &:last-child:after {
      display: none;
    }

    > button {
      padding: 0;
      position: relative;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background-color: $progress-line-color;
      color: $progress-line-color;
      border: 4px solid $progress-indicator-border;
      vertical-align: middle;
      border-radius: 1rem;
      line-height: 1;
      user-select: none;
      white-space: normal;
      z-index: 1;

      .bg-faded & {
        border-color: $brand-default;
      }
      .bg-inverse & {
        border-color: $brand-inverse;
      }
    }
  }

  > .has-error,
  > .has-completed {
    > button {
      width: 22px;
      height: 22px;

      svg {
        width: .875rem;
        height: .875rem;
        fill: currentColor;
      }
    }
  }

  .progress-list-indicator {
    display: none;
  }

  > .active {
    @include progress-list-state($component-active-bg);
  }
  > .info {
    @include progress-list-state($brand-info);
  }
  > .success {
    @include progress-list-state($brand-success);
  }
  > .warning {
    @include progress-list-state($brand-warning);
  }
  > .has-error {
    @include progress-list-state($brand-danger);
  }

  > .active,
  > .has-completed,
  > .has-error {
    .progress-list-indicator {
      display: block;
    }
  }
}