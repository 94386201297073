.panel {
  border-color: $panel-default-border;
  box-shadow: none;
  // box-shadow: 0 1px 3px -1px rgba(#000, .1);
  > pre {
    margin-bottom: 0;
    border-radius: 0;
    border-width: 0;

    &,
    .hljs {
      padding-right: $panel-body-padding;
      padding-left: $panel-body-padding;
    }

    &:last-child {
      @include border-bottom-radius($border-radius-base);
    }
  }

  > .list-group > .list-group-item {
    background-color: transparent;
  }

  > .btn-block {
    border-radius: 0;
    &:first-child {
      @include border-top-radius($border-radius);
    }
    &:last-child {
      @include border-bottom-radius($border-radius);
    }
  }

  > .alert {
    margin-bottom: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
  }
}

.panel-heading {
  @include clearfix;
  .panel-title {
    font-size: 1rem;
  }
  + .panel-body {
    padding-top: ($line-height-computed - $panel-heading-padding);
  }
}

.panel-heading,
.panel-title {
  font-weight: normal;
  line-height: 1.125em;

  > a {
    @include hover-focus {
      text-decoration: none;
    }
  }
  > a,
  .panel-link {
    @include hover-focus {
      color: $gray-darker;
    }
  }
  // normalize headings
  &.h1 { font-size: $font-size-h1; }
  &.h2 { font-size: $font-size-h2; }
  &.h3 { font-size: $font-size-h3; }
  &.h4 { font-size: $font-size-h4; }
  &.h5 { font-size: $font-size-h5; }
  &.h6 { font-size: $font-size-h6; }
}

.panel-subtitle {
  margin-bottom: 0;
  color: $text-muted;
  font-weight: normal;

  .panel-title + & {
    margin-top: 0;
  }
  .panel-link {
    color: inherit;
    @include hover-focus {
      color: $gray;
    }
  }
}

.panel-icon {
  margin-right: .5em;

  [class*="icon-"] {
    font-size: $element-font-size-lg;
    line-height: 1;
  }
}

.panel-text {
  margin-bottom: $panel-body-padding;
  &:last-child {
    margin-bottom: 0;
  }
}

.panel-link {
  @include hover-focus {
    text-decoration: none;
  }

  + .panel-link {
    margin-left: 1em;
  }
}

.panel-group-indicator {
  .panel-title > a {
    &:before {
      content: "\f068";
      margin-right: 10px;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.collapsed:before {
      content: "\f067";
    }
  }
}
.panel-group-indicator-right {
  .panel-title > a {
    &:before {
      float: right;
      margin-right: 0;
      margin-left: 10px;
      line-height: $element-font-size-lg;
    }
  }
}

.panel-body {
  + .panel-heading {
    border-top: 1px solid transparent;
    border-radius: 0;
  }
  + .panel-body {
    padding-top: ($line-height-computed - $panel-body-padding);
  }

  > .panel-title,
  > .panel-subtitle:not(:last-child) {
    margin-bottom: $panel-body-padding;
  }
}

// Card image
.panel-img {
  border-radius: $panel-border-radius;
}
.panel-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $panel-body-padding;
}
// panel image caps
.panel-img-top {
  @include border-top-radius($panel-border-radius);
}
.panel-img-bottom {
  @include border-bottom-radius($panel-border-radius);
}

.panel-dark {
  border-color: $brand-inverse;
  > .panel-heading {
    background-color: $brand-inverse;
    color: #fff;
  }
}
.panel-primary {
  border-color: $brand-primary;
  > .panel-heading {
    background-color: $brand-primary;
    color: #fff;
  }
}
.panel-success {
  border-color: $brand-success;
  > .panel-heading {
    background-color: $brand-success;
    color: #fff;
  }
}
.panel-info {
  border-color: $brand-info;
  > .panel-heading {
    background-color: $brand-info;
    color: #fff;
  }
}
.panel-warning {
  border-color: $brand-warning;
  > .panel-heading {
    background-color: $brand-warning;
    color: #fff;
  }
}
.panel-danger {
  border-color: $brand-danger;
  > .panel-heading {
    background-color: $brand-danger;
    color: #fff;
  }
}

.panel-default {
  .panel-heading {
    + .panel-body {
      padding-top: $panel-body-padding;
    }
  }
}

.panel-dark,
.panel-primary,
.panel-info,
.panel-success,
.panel-warning,
.panel-danger,
.panel-inverse {
  .panel-heading,
  .panel-title {
    > a,
    .panel-link {
      @include hover-focus {
        color: #fff;
      }
    }
  }
}


//
// Background variations
//

.panel-filled-primary {
  @include panel-variant($brand-primary, $brand-primary);
}
.panel-filled-success {
  @include panel-variant($brand-success, $brand-success);
}
.panel-filled-info {
  @include panel-variant($brand-info, $brand-info);
}
.panel-filled-warning {
  @include panel-variant($brand-warning, $brand-warning);
}
.panel-filled-danger {
  @include panel-variant($brand-danger, $brand-danger);
}
.panel-filled-default {
  @include panel-variant($brand-default, $brand-default);
}
.panel-filled-dark {
  @include panel-variant($brand-inverse, $brand-inverse);
}

// Remove all backgrounds
.panel-outline-primary {
  @include panel-outline-variant($btn-primary-bg);
}
.panel-outline-secondary {
  @include panel-outline-variant($btn-secondary-border);
}
.panel-outline-info {
  @include panel-outline-variant($btn-info-bg);
}
.panel-outline-success {
  @include panel-outline-variant($btn-success-bg);
}
.panel-outline-warning {
  @include panel-outline-variant($btn-warning-bg);
}
.panel-outline-danger {
  @include panel-outline-variant($btn-danger-bg);
}
.panel-outline-default {
  @include panel-outline-variant($btn-default-border);
}
.panel-outline-dark {
  @include panel-outline-variant($brand-inverse);
}


//
// Inverse text within a panel for use with dark backgrounds
//

.panel-inverse {
  @include panel-inverse;

  .panel-group & {
    .panel-heading + .panel-collapse{
      > .list-group,
      > .panel-body {
        border-color: rgba(#fff, .25);
      }
    }
  }
}