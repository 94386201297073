.panel-tools {
  margin: -5px 0;
  position: relative;
  float: right;
  z-index: 2;

  .progress {
    min-width: 200px;
  }
  .custom-control {
    margin: 0;
  }

  .panel-extra {
    display: inline-block;
    margin: 4px 0;
    padding: 0;
  }

  .input-group {
    width: 260px;
  }
  .form-group-sm .input-group {
    width: 200px;
  }

  .btn,
  .form-group {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .btn-sm,
  .form-group-sm {
    margin-top: 0;
    margin-bottom: 0;
  }
  .btn-lg,
  .form-group-lg {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  > .nav {
    font-size: $element-font-size;
    > li {
      display: inline-block;
      > a {
        padding: 5px;
        min-width: 24px;
        text-align: center;
      }
    }

    &.nav-inverse > li > a {
      @include hover-focus {
        background-color: rgba(#fff, .15);
      }
    }
  }
}

.panel-extra {
  display: block;
  padding: 10px $panel-body-padding;
  @include clearfix;
}

// has loading
.has-loading {
  cursor: wait;
}

// close
.panel-closed {
  display: none;
}

// collapse
.panel-collapsed {
  &:not(.panel-expanded):not(.panel-fullscreen) {
    .panel-heading {
      border-bottom-width: 0;
      @include border-bottom-radius($panel-border-radius);
    }
  }

  > .has-collapsed {
    display: none;
  }
}

// expand
.panel.panel-expanded {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  border-radius: 0;
  overflow-y: auto;
}


// Panel utility classes
.show-on-panel-collapsed,
.show-inline-on-panel-collapsed,
.show-on-panel-expanded,
.show-inline-on-panel-expanded {
  display: none !important;
}
// on collapsed
.panel-collapsed {
  .hide-on-panel-collapsed {
    display: none !important;
  }
  .show-on-panel-collapsed {
    display: block !important;
  }
  .show-inline-on-panel-collapsed {
    display: inline-block !important;
  }
}
// on expand
.panel-expanded {
  .hide-on-panel-expanded {
    display: none !important;
  }
  .show-on-panel-expanded {
    display: block !important;
  }
  .show-inline-on-panel-expanded {
    display: inline-block !important;
  }
}