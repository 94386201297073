.tile {
  position: relative;
  display: block;
  max-width: 100%;
  background-color: #fff;
  color: $text-color;
  border: 1px solid darken($border-color, 5%);
  border-radius: $border-radius;
  margin-bottom: ($line-height-computed/2);

  &.tile-hover:hover {
    box-shadow: 0 2px 4px rgba(#000, 0.1);
  }

  @media (max-width: $screen-xs-max) {
    max-width: 320px;
  }
}
.tile-link {
  display: block;
  color: $text-color;

  @include hover-focus {
    color: $text-color;
    text-decoration: none;
  }
}

.tile-content {
  position: relative;
  @include clearfix;
}

.tile-icon,
.tile-preview,
.tile-truncate-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tile-icon {
  margin-top: -21px;
}
.tile-preview {
  margin-top: -1rem;
  max-width: 100%;
}
.tile-no-peek {
  .tile-preview,
  .tile-icon {
    margin-top: 0;
  }
}
.tile-truncate-text {
  padding: .5em;
  font-size: 28px;
  width: 100%;
  text-align: center;
}
.tile-truncate-text,
.tile-peek {
  small {
    display: block;
    font-size: 12px;
  }
}

.tile-peek {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: .5rem;
  font-size: 12px;
  vertical-align: middle;
  background-color: $body-bg;
  @include clearfix;
}

.tile-actions {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  font-size: 12px;

  > ul {
    margin-bottom: 0;

    a {
      color: $text-muted;

      @include hover-focus {
        color: $link-hover-color;
        text-decoration: none;
      }
    }
  }
}

// tile picker
.tile-picker {
  display: inline-block;
  margin-bottom: ($line-height-computed/2);
  vertical-align: top;

  input[type="checkbox"],
  input[type="radio"] {
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:checked ~ .tile {
      background-color: $component-active-bg;
      color: $component-active-color;

      .tile-icon {
        display: none;
      }
      .tile-has-selected {
        display: block;
      }
    }
  }

  &.tile-picker-outline {
    input[type="checkbox"],
    input[type="radio"] {
      &:checked ~ .tile {
        background-color: #fff;
        color: $text-color;
        outline: 0;
        border-color: $component-active-bg;
        box-shadow: 0 0 0 1px $component-active-bg inset;

        .tile-icon {
          display: block;
        }
        .tile-has-selected {
          position: absolute;
          top: -0.0625rem;
          right: 0.625rem;
          left: auto;
          width: 0.5rem;
          height: 0.5rem;
          color: $component-active-color;
          z-index: 1;
          transform: none;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          border-color: transparent;
          border-style: solid;
          border-radius: $border-radius;
          border-width: 1rem;
          border-right-color: $component-active-bg;
          border-top-color: $component-active-bg;
        }
      }
    }
  }

  .tile {
    margin-bottom: .5em;
    cursor: pointer;

    &:hover {
      outline: 0;
      border-color: $component-active-bg;
      box-shadow: 0 0 0 1px $component-active-bg inset;
    }
  }
  .tile-content {
    display: block;
    width: 180px;
    height: 180px;
  }
  .tile-icon {
    margin-top: 0;
  }
  .tile-has-selected {
    display: none;
  }
  .tile-peek {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    bottom: auto;
    left: auto;
    width: 180px;
    text-align: center;
    background-color: transparent;
    font-size: $font-size-base;
  }
}
a.tile-picker .tile:hover {
  box-shadow: none;
}
a.tile-picker .tile-hover:hover {
  box-shadow: 0 2px 4px rgba(#000, 0.1);
}
// sizing
// small
.tile-picker-sm {
  .tile-content {
    width: 72px;
    height: 72px;
  }
  .tile-peek {
    width: 72px;
  }
}
// large
.tile-picker-lg {
  .tile-content {
    width: 240px;
    height: 240px;
  }
  .tile-peek {
    width: 240px;
  }
}

// tile media
.tile-media {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid darken($border-color, 5%);
  border-radius: $border-radius;
  margin-bottom: ($line-height-computed/2);

  .tile {
    border-radius: 0;
    margin-bottom: 0;
  }
}