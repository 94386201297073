.disabled {
  color: $text-muted;
  cursor: $cursor-disabled;
  user-select: none;

  > select,
  > input[type="checkbox"],
  > input[type="radio"],
  > input[type="file"],
  > .form-control,
  > .input-group-btn .btn {
    pointer-events: none;
    user-select: none;
  }

  > .form-control,
  > .custom-select,
  > .input-group-addon {
    color: $text-muted;
    background-color: $input-bg-disabled;
  }
  > .input-group-addon .fa-spin {
    animation: none;
  }
  > .input-group-btn .btn {
    @include opacity(.65);
  }
  &.input-group-select .input-group-addon {
    background-color: transparent;
  }
  &.input-group-select-nofill {
    border-bottom: 1px solid $input-border;
    > .custom-select {
      background-color: transparent;
    }
  }
}

.form-control[disabled] {
  + .input-group-addon {
    color: $text-muted;
    background-color: $input-bg-disabled;
    cursor: not-allowed;
  }
}

.form-control-feedback {
  z-index: 3;
}

// feedback states
.has-success {
  .form-control {
    border-color: $state-success-border;
    &:focus {
      border-color: darken($state-success-border, 10%);
    }
  }
  .input-group-addon {
    border-color: $state-success-border;
    background-color: inherit;
  }
  > .focusin,
  &.focusin {
    .input-group-addon {
      border-color: darken($state-success-border, 10%);
    }
  }
  .btn-clearable {
    color: $state-success-text;
  }
  .popover,
  .popover-title {
    color: #fff;
    background-color: $brand-success;
    border-color: darken($brand-success, 10%);
  }
  .popover {
    &.top > .arrow {
      border-top-color: darken($brand-success, 5%);
      &:after {
        border-top-color: $brand-success;
      }
    }
    &.right > .arrow {
      border-right-color: darken($brand-success, 5%);
      &:after {
        border-right-color: $brand-success;
      }
    }
    &.bottom > .arrow {
      border-bottom-color: darken($brand-success, 5%);
      &:after {
        border-bottom-color: $brand-success;
      }
    }
    &.left > .arrow {
      border-left-color: darken($brand-success, 5%);
      &:after {
        border-left-color: $brand-success;
      }
    }
  }

  .tooltip-inner {
    background-color: $brand-success;
  }
  .tooltip {
    &.top .tooltip-arrow,
    &.top-left .tooltip-arrow,
    &.top-right .tooltip-arrow {
      border-top-color: $brand-success;
    }
    &.right .tooltip-arrow {
      border-right-color: $brand-success;
    }
    &.left .tooltip-arrow {
      border-left-color: $brand-success;
    }
    &.bottom .tooltip-arrow,
    &.bottom-left .tooltip-arrow,
    &.bottom-right .tooltip-arrow {
      border-bottom-color: $brand-success;
    }
  }
}
.has-warning {
  .form-control {
    border-color: $state-warning-border;
    &:focus {
      border-color: darken($state-warning-border, 10%);
    }
  }
  .input-group-addon {
    border-color: $state-warning-border;
    background-color: inherit;
  }
  > .focusin,
  &.focusin {
    .input-group-addon {
      border-color: darken($state-warning-border, 10%);
    }
  }
  .btn-clearable {
    color: $state-warning-text;
  }
  .popover,
  .popover-title {
    color: #fff;
    background-color: $brand-warning;
    border-color: darken($brand-warning, 10%);
  }
  .popover {
    &.top > .arrow {
      border-top-color: darken($brand-warning, 5%);
      &:after {
        border-top-color: $brand-warning;
      }
    }
    &.right > .arrow {
      border-right-color: darken($brand-warning, 5%);
      &:after {
        border-right-color: $brand-warning;
      }
    }
    &.bottom > .arrow {
      border-bottom-color: darken($brand-warning, 5%);
      &:after {
        border-bottom-color: $brand-warning;
      }
    }
    &.left > .arrow {
      border-left-color: darken($brand-warning, 5%);
      &:after {
        border-left-color: $brand-warning;
      }
    }
  }

  .tooltip-inner {
    background-color: $brand-warning;
  }
  .tooltip {
    &.top .tooltip-arrow,
    &.top-left .tooltip-arrow,
    &.top-right .tooltip-arrow {
      border-top-color: $brand-warning;
    }
    &.right .tooltip-arrow {
      border-right-color: $brand-warning;
    }
    &.left .tooltip-arrow {
      border-left-color: $brand-warning;
    }
    &.bottom .tooltip-arrow,
    &.bottom-left .tooltip-arrow,
    &.bottom-right .tooltip-arrow {
      border-bottom-color: $brand-warning;
    }
  }
}
.has-error {
  .form-control {
    border-color: $state-danger-border;
    &:focus {
      border-color: darken($state-danger-border, 10%);
    }
  }
  .input-group-addon {
    border-color: $state-danger-border;
    background-color: inherit;
  }
  > .focusin,
  &.focusin {
    .input-group-addon {
      border-color: darken($state-danger-border, 10%);
    }
  }
  .btn-clearable {
    color: $state-danger-text;
  }
  .popover,
  .popover-title {
    color: #fff;
    background-color: $brand-danger;
    border-color: darken($brand-danger, 10%);
  }
  .popover {
    &.top > .arrow {
      border-top-color: darken($brand-danger, 5%);
      &:after {
        border-top-color: $brand-danger;
      }
    }
    &.right > .arrow {
      border-right-color: darken($brand-danger, 5%);
      &:after {
        border-right-color: $brand-danger;
      }
    }
    &.bottom > .arrow {
      border-bottom-color: darken($brand-danger, 5%);
      &:after {
        border-bottom-color: $brand-danger;
      }
    }
    &.left > .arrow {
      border-left-color: darken($brand-danger, 5%);
      &:after {
        border-left-color: $brand-danger;
      }
    }
  }

  .tooltip-inner {
    background-color: $brand-danger;
  }
  .tooltip {
    &.top .tooltip-arrow,
    &.top-left .tooltip-arrow,
    &.top-right .tooltip-arrow {
      border-top-color: $brand-danger;
    }
    &.right .tooltip-arrow {
      border-right-color: $brand-danger;
    }
    &.left .tooltip-arrow {
      border-left-color: $brand-danger;
    }
    &.bottom .tooltip-arrow,
    &.bottom-left .tooltip-arrow,
    &.bottom-right .tooltip-arrow {
      border-bottom-color: $brand-danger;
    }
  }
}

// remove shadow from feedback states
.has-success,
.has-warning,
.has-error {
  .form-control {
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }
  .input-group-select-nofill > .custom-select {
    border-width: 0 0 1px;
    border-style: dashed;
    border-radius: 0;
  }
}