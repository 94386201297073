.switcher-control {
  margin-bottom: 0;
  position: relative;
  width: 36px;
  vertical-align: middle;
  user-select:none;
}
.switcher-input {
  display: none;
}
.switcher-indicator {
  margin-bottom: 0;
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 16px;
  padding: 0;
  line-height: 16px;
  border: 2px solid $custom-control-indicator-bg;
  border-radius: 16px;
  background-color: $custom-control-indicator-bg;
  transition: background-color 150ms linear;
}
.switcher-indicator:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  width: 16px;
  margin: 0px;
  background: #fff;
  right: 20px;
  border: 2px solid #fff;
  border-radius: 16px;
  transition: all 150ms linear 0s;
}
.switcher-input:checked {
  + .switcher-indicator,
  + .switcher-indicator:before {
    border-color: $brand-primary;
  }
  + .switcher-indicator {
    background-color: $brand-primary;
  }
  + .switcher-indicator:before {
    right: 0px;
    box-shadow: none;
  }
}
.disabled > .switcher-input,
.switcher-input:disabled {
  pointer-events: none;
  + .switcher-indicator {
    opacity: .65;
    cursor: not-allowed;
  }
}

// variant colors
.switcher-control-success {
  @include switcher-variant($brand-success);
}
.switcher-control-info {
  @include switcher-variant($brand-info);
}
.switcher-control-warning {
  @include switcher-variant($brand-warning);
}
.switcher-control-danger {
  @include switcher-variant($brand-danger);
}