.composer {
  display: table;
  width: 100%;

  .composer-label,
  .composer-actions {
    display: none;
  }

  &.has-focus {
    display: block;
    @include clearfix;

    .composer-label,
    .composer-actions {
      display: block;
    }
    .composer-input,
    .composer-btn {
      display: block;
    }
    .composer-label {
      margin-bottom: .75em;
    }
    .composer-input {
      margin-bottom: .5em;
      padding-right: 0;
      width: 100%;
      .form-control {
        height: auto;
        resize: both;
      }
    }
    .composer-actions {
      float: left;
    }
    .composer-btn {
      float: right;
    }
  }
}

.composer-input,
.composer-btn {
  display: table-cell;
}
.composer-input {
  padding-right: 12px;
  width: 10000px;

  .form-control {
    height: $input-height-base;
    resize: none;
  }
}

.composer-alt {
  border: 1px solid $input-border;
  border-radius: $border-radius-base;
  background-color: $input-bg;

  .composer-input {
    margin-bottom: 0;
  }
  .composer-input {
    padding-right: 0;

    .form-control {
      border: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }
  .composer-btn {
    display: none;
    padding: 0 1em .75em 0;
  }
  .composer-actions {
    padding: 0 .5em .75em;
  }

  &.has-focus {
    border-color: $input-border-focus;
    box-shadow: 0 0 8px rgba($input-border-focus, .25);

    .composer-input {
      margin-top: 0;
    }
  }
}


// Docked composer
.composer-docked {
  position: fixed;
  bottom: 0;
  right: 20px;
  margin-bottom: 0;
  width: 320px;
  z-index: ($zindex-modal-background - 1);
  float: left;
  background-color: $dropdown-bg;
  border-radius: $border-radius-base;
  background-clip: padding-box;
  box-shadow: $dropdown-shadow;
  transition: width 150ms;

  @media (max-width: $screen-xs-max) {
    right: 0;
    max-width: 100%;
  }

  &-lg:not(.panel-collapsed) {
    width: 480px;
  }

  &-inline:not(.panel-expanded) {
    position: relative;
    bottom: auto;
    right: auto;
    float: none;
    max-width: 100%;
    z-index: auto;
  }

  &.panel-collapsed {
    .panel-heading {
      @include border-bottom-radius(0 !important);
    }
  }
  &.panel-expanded {
    width: 100%;
    transition: none;
    .composer-docked-body {
      height: auto;
      min-height: 480px;
    }
  }
}
.composer-docked-body {
  position: relative;
  height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-docked {
  .composer-docked:not(.panel-expanded) {
    margin: 30px auto;
  }
  // When fading in the modal, animate it to slide down
  &.fade .composer-docked {
    @include translate(0, -25%);
    @include transition-transform(0.3s ease-out);
  }
  &.in .composer-docked { @include translate(0, 0) }
}