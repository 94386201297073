
//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  background-color: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: $element-font-size;

  // Share hover and focus styles
  @include hover-focus-active {
    color: $link-color;
    background-color: transparent;
  }
  &.btn-link {
    color: $link-hover-color;
    font-weight: $btn-font-weight;
    background-color: transparent;
  }

  [class^="icon-"] {
    vertical-align: middle;
  }
  &:not(.btn-sm) .icon {
    // margin: -0px .25rem;
    font-size: 1.125em;
    line-height: 1.1em;
    font-weight: normal;
  }
  .img-icon {
    width: 1em;
    height: auto;
  }

  &-pill {
    border-radius: 36px;
  }

  .btn-group-lg > &,
  &-lg {
    font-size: $element-font-size-lg;
    padding: 12px 20px;

    .icon {
      line-height: 1;
    }
  }
  .btn-group-sm > &,
  &-sm {
    font-size: $element-font-size-sm;
  }
}

.btn-unstyled {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border: none;
  outline: 0 !important;

  @include hover-focus-active {
    background-color: transparent;
    box-shadow: none;
  }
}

// Alternate buttons
// --------------------------------------------------

// best for icon only
.btn-circle {
  width: $input-height-base;
  border-radius: ($input-height-large/2);

  > .icon,
  > .fa,
  > [class^="icon-"] {
    margin: 0;
    padding: 0;
    font-size: inherit;
    line-height: ($input-height-base - 2);
  }

  &,
  &.btn-raised,
  &[class*="btn-outline-"] {
    &,
    &.btn-lg,
    &.btn-sm {
      padding: 0;
    }
  }

  &.btn-lg {
    width: $input-height-large;
    > .icon,
    > .fa,
    > [class^="icon-"] {
      line-height: ($input-height-large - 2);
    }
  }
  &.btn-sm {
    width: $input-height-small;
    > .icon,
    > .fa,
    > [class^="icon-"] {
      line-height: ($input-height-small - 2);
    }
  }
}

// Context
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
  .badge {
    background-color: $btn-default-color;
  }
  @include hover-focus {
    color: $text-color;
  }
}
.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}
.btn-inverse {
  @include button-variant($btn-inverse-color, $btn-inverse-bg, $btn-inverse-border);
}
.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Remove all backgrounds
.btn-outline-default {
  @include button-outline-variant(#fff, $btn-default-color);
}
.btn-outline-secondary {
  @include button-outline-variant($btn-secondary-bg, $btn-secondary-color);
}
.btn-outline-inverse {
  @include button-outline-variant($btn-inverse-bg);
}
.btn-outline-primary {
  @include button-outline-variant($btn-primary-bg);
}
.btn-outline-info {
  @include button-outline-variant($btn-info-bg);
}
.btn-outline-success {
  @include button-outline-variant($btn-success-bg);
}
.btn-outline-warning {
  @include button-outline-variant($btn-warning-bg);
}
.btn-outline-danger {
  @include button-outline-variant($btn-danger-bg, $btn-danger-color);
}

// add shadow
.btn-default,
.btn-outline-default {
  @include button-raised-variant(#fff);
}
.btn-secondary,
.btn-outline-secondary {
  @include button-raised-variant($btn-secondary-bg);
}
.btn-inverse,
.btn-outline-inverse {
  @include button-raised-variant($btn-inverse-bg);
}
.btn-primary,
.btn-outline-primary {
  @include button-raised-variant($btn-primary-bg);
}
.btn-info,
.btn-outline-info {
  @include button-raised-variant($btn-info-bg);
}
.btn-success,
.btn-outline-success {
  @include button-raised-variant($btn-success-bg);
}
.btn-warning,
.btn-outline-warning {
  @include button-raised-variant($btn-warning-bg);
}
.btn-danger,
.btn-outline-danger {
  @include button-raised-variant($btn-danger-bg);
}


// add .icon to buttons
.btn-icon {
  text-align: left;

  > .icon {
    margin: 0 $padding-base-horizontal 0 0;
    padding: 0 $padding-base-horizontal 0 0;
    float: left;
    border-right: 1px solid rgba(#fff, .30);
  }

  &-right {
    > .icon {
      margin: 0 0 0 $padding-base-horizontal;
      padding: 0 0 0 $padding-base-horizontal;
      float: right;
      border-right: none;
      border-left: 1px solid rgba(#fff, .30);
    }
  }

  &[class*="btn-outline-"] {
    > .icon {
      border-color: inherit;
    }
  }

  &.btn-default,
  &.btn-secondary {
    > .icon {
      border-color: rgba(#000, .15);
    }
  }

  @include hover-focus {
    > .icon {
      border-color: rgba(#fff, .30);
    }
  }

  &.btn-default,
  &.btn-secondary {
    &:hover,
    &:focus, {
      > .icon {
        border-color: rgba(#000, .15);
      }
    }
  }
}

// compact
.btn-compact {
  padding-right: ($padding-base-vertical + 4);
  padding-left: ($padding-base-vertical + 4);

  &.btn-lg {
    padding-right: (12px + 4);
    padding-left: (12px + 4);
  }
  &.btn-sm {
    padding-right: ($padding-small-vertical + 2);
    padding-left: ($padding-small-vertical + 2);
  }
}


// count
.count {
  position: relative;
  margin-left: 4px;
  display: inline-block;
  padding: $padding-base-vertical $padding-base-horizontal;
  border: 1px solid darken($border-color, 5%);
  font-size: $element-font-size;
  font-weight: 500;
  color: $text-color;
  vertical-align: middle;
  border-radius: $border-radius-base;

  &.arrow {
    margin-left: 8px;
  }

  .btn-lg + & {
    font-size: $element-font-size-lg;
    padding: $padding-large-vertical $padding-large-horizontal;
  }
  .btn-sm + & {
    font-size: $element-font-size-sm;
    padding: $padding-small-vertical $padding-small-horizontal;
  }
}

// arrow
.count.arrow {
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    pointer-events: none;
    border-color: transparent;
    border-style: solid;
    top: 50%;
  }
  &:before {
    left: -6px;
    margin-top: -6px;
    border-width: 7px 5px 7px 0;
    border-right-color: inherit;
  }
  &:after {
    left: -5px;
    margin-top: -9px;
    border-width: 10px 7px 10px 0;
    border-right-color: $body-bg;
  }
}


// Custom behavior
.btn-collapse-icon {
  text-align: left;

  > .caret {
    border: none;
    width: auto;
    height: auto;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    float: right;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
      content: "\f0da";
    }
  }
  &.active > .caret:before {
    content: "\f0d7";
  }
}