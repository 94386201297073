body {
  position: relative;
}

//
// Type
// --------------------------------------------------

mark, .mark {
  background-color: tint(#FFC300, 80%);
}

dt {
  font-weight: 500;
}

blockquote {
  p {
    color: $gray;
  }
}

b,
strong {
  font-weight: 500;
}

pre {
  $bg-size: 48px;
  padding: 0 .5em;
  line-height: $bg-size/2;
  background-image: linear-gradient($pre-bg 50%, darken($pre-bg, 3%) 50%);
  background-size: $bg-size $bg-size;
  background-attachment: local;
}

.page-header {
  margin: $line-height-computed 0 ($line-height-computed/2);
  padding-bottom: 0;
  font-size: 24px;
  font-weight: 400;
  border-bottom: none;

  small {
    font-size: 14px;
  }
}