.tickets {
  margin-bottom: 0;
  .list-group-item {
    padding-right: 0px;
    padding-left: 0px;

    ul.list-inline {
      margin-bottom: 0;
      font-size: $font-size-small;
      color: $text-muted;
    }
  }
  .list-group-item-heading {
    @include text-truncate();
  }
}


// Tasks list widget
.tasks-wrapper {
  overflow-y: auto;
  border-bottom: 1px solid darken($border-color, 2%)
}
.tasks {
  margin-bottom: 0px;
  .list-group-header,
  .list-group-item {
    padding-right: 0px;
    padding-left: 0px;

    &:last-child {
      border: 0;
    }
  }
  .list-group-item {
    outline: 0;
    @include hover-focus {
      background-color: transparent;
      .fa-stack-1x {
        color: $text-muted;
      }
    }
    &.done {
      color: $text-muted;
      .task-description {
        text-decoration: line-through;
      }
      .fa-stack-1x {
        color: $brand-danger;
      }

      @include hover-focus {
        color: $text-muted;
      }
    }
  }

  .fa-stack {
    top: 2px;
    margin-top: -5px;
    margin-right: 8px;
  }
  .fa-stack-2x {
    color: $brand-default;
  }
  .fa-stack-1x {
    color: $brand-default;
  }
}


// Quick message
.quick-message {
  .list-group,
  .note-editor {
    margin-bottom: 0;
  }
  .note-editor {
    border: 0;
  }

  .input-group {
    border-bottom: 1px solid $input-border;
  }
  .form-control,
  .input-group-addon {
    border: 0;
  }
  .focusin,
  .form-control:focus {
    box-shadow: none;
  }
}