/**
 * Make an element show only if parent receive hover/focus event
 */

.hover-control {

  @include hover-focus {
    .hover-control-item {
      @include opacity(1);
      visibility: visible;
    }
  }
  .hover-control-item {
    @include opacity(0);
    visibility: hidden;
    transition: all 150ms linear;
  }
}