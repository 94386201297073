$activity-border-color:       darken($border-color, 3%) !default;

.activity {
  margin: 0 0 $line-height-computed;
  padding: 0;
  display: block;
}

.activity-heading {
  margin-top: 0;
  margin-bottom: ($line-height-computed/2);
}

.activity-item {
  position: relative;
  display: block;
  padding-bottom: $line-height-computed;
  @include clearfix;

  &:last-child {
    padding-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 13px;
    border-left: 3px solid $activity-border-color;
  }

  .activity-alt &::before {
    top: 3em;
    bottom: 1.5em;
    border-left-color: inherit;
  }

  .activity-dotted &:before {
    left: 6px;
  }

  .activity-dotted.activity-alt &:before {
    top: 2em;
    bottom: 1em;
    border-left-color: $activity-border-color;
  }
}

.activity-figure {
  margin-right: -100%;
  position: relative;
  float: left;

  .activity-dotted & {
    user-select: none;
    display: inline-block;
    position: relative;
    padding: 0;
    vertical-align: middle;
    border: 4px solid $activity-border-color;
    background-color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    line-height: 1;
  }
}

.activity-body {
  float: left;
  width: 100%;
  padding-left: 46px;

  .activity-dotted & {
    padding-left: 32px;
  }
}

// inverted
.activity-inverted {
  .activity-item {
    &::before {
      right: 13px;
      left: auto;
      margin-left: auto;
    }
  }
  .activity-figure {
    float: right;
    margin-right: auto;
    margin-left: -100%;
  }
  .activity-body {
    float: right;
    padding-right: 3rem;
    padding-left: initial;
    width: 100%;
  }

  &.activity-dotted &:before {
    right: 6px;
    left: auto;
  }
}