// Custom input
// #

.custom-input {
  border-width: 0px 0px 1px;
  border-radius: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;

  &.custom-select {
    border-width: 0px 0px 1px;
  }

  &:focus {
    box-shadow: none;
  }
  .disabled > &,
  &:disabled {
    background-color: transparent;
    pointer-events: none;
  }
}


// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  position: relative;
  display: inline-block;
  min-height: (1rem * $line-height-base);
  padding-left: $custom-control-gutter;
  margin-right: $custom-control-spacer-x;
  font-size: $element-font-size-lg;
  font-weight: normal;
  vertical-align: middle;

  .form-horizontal & {
    margin-top: 3px;
  }
}

.custom-control-input {
  position: absolute;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  opacity: 0;

  &:checked ~ .custom-control-indicator {
    color: $custom-control-checked-indicator-color;
    background-color: $custom-control-checked-indicator-bg;
    @include box-shadow($custom-control-checked-indicator-box-shadow);
  }

  &:focus ~ .custom-control-indicator {
    // the mixin is not used here to make sure there is feedback
    box-shadow: $custom-control-focus-indicator-box-shadow;
  }

  &:active ~ .custom-control-indicator {
    color: $custom-control-active-indicator-color;
    background-color: $custom-control-active-indicator-bg;
    @include box-shadow($custom-control-active-indicator-box-shadow);
  }

  .disabled > &,
  &:disabled {
    ~ .custom-control-indicator {
      cursor: $custom-control-disabled-cursor;
      background-color: $custom-control-disabled-indicator-bg;
    }

    ~ .custom-control-description {
      color: $custom-control-disabled-description-color;
      cursor: $custom-control-disabled-cursor;
    }
  }
}

// Custom indicator
//
// Generates a shadow element to create our makeshift checkbox/radio background.

.custom-control-indicator {
  position: absolute;
  top: (($line-height-base - $custom-control-indicator-size) / 2);
  left: 0;
  display: block;
  width: $custom-control-indicator-size;
  height: $custom-control-indicator-size;
  pointer-events: none;
  user-select: none;
  background-color: $custom-control-indicator-bg;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $custom-control-indicator-bg-size;
  @include box-shadow($custom-control-indicator-box-shadow);
}

.custom-control-description {
  display: inline-block;
  margin-top: 2px;
}

// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
  .custom-control-indicator {
    border-radius: $custom-checkbox-radius;
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    background-image: $custom-checkbox-checked-icon;
  }

  .custom-control-input:indeterminate ~ .custom-control-indicator {
    background-color: $custom-checkbox-indeterminate-bg;
    background-image: $custom-checkbox-indeterminate-icon;
    @include box-shadow($custom-checkbox-indeterminate-box-shadow);
  }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
  .custom-control-indicator {
    border-radius: $custom-radio-radius;
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    background-image: $custom-radio-checked-icon;
  }
}


// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// http://primercss.io.
//

.custom-select {
  display: block;
  width: 100%;
  max-width: 100%;
  font-size: $element-font-size-lg;
  height: $input-height-base;
  line-height: 1;
  padding: $custom-select-padding-y 36px $custom-select-padding-y $custom-select-padding-x;
  color: $custom-select-color;
  vertical-align: middle;
  background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
  background-size: $custom-select-bg-size;
  border: $custom-select-border-width solid $custom-select-border-color;
  border-radius: $custom-select-border-radius;
  // Use vendor prefixes as `appearance` isn't part of the CSS spec.
  appearance: none;

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: none;
    @include box-shadow($custom-select-focus-box-shadow);

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // supress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &:disabled {
    color: $custom-select-disabled-color;
    cursor: $cursor-disabled;
    background-color: $custom-select-disabled-bg;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    opacity: 0;
  }

  &-pill {
    border-radius: 30px;
  }
}

.custom-select-inline {
  display: inline-block;
  width: auto;
}

.input-group-select {
  .input-group-addon {
    display: block;
    position: absolute;
    line-height: 1.6;
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    background-color: transparent;
    border-color: transparent;
    user-select: none;
    z-index: 2;

    i:empty,
    .fa,
    [class^="icon-"] {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  .custom-select {
    display: block;
    padding-left: 36px;
    z-index: 1;
  }
}

// remove background and border
.input-group-select-nofill {
  .input-group-addon {
    padding-left: 0;
    padding-right: 0;
  }
  .custom-select {
    background-color: transparent;
    border-color: transparent;
    padding-left: 20px;

    &:focus {
      border-bottom: 1px dashed $gray-light;
    }
  }
}

// sizing
.form-group-sm .custom-select,
.input-group-sm > .custom-select,
.custom-select-sm {
  padding: 4px 12px;
  font-size: $custom-select-sm-font-size;
  height: $input-height-small;
  line-height: 1.1;
}
.form-group-sm > .input-group-select,
.input-group-sm.input-group-select {
  .input-group-addon {
    padding-left: 12px;
    padding-right: 12px;
  }
  .custom-select {
    padding-left: $input-height-small;
  }
  &.input-group-select-nofill {
    .input-group-addon {
      padding-left: 0;
    }
    .custom-select {
      padding-left: 16px;
    }
  }
}

.form-group-lg .custom-select,
.input-group-lg > .custom-select,
.custom-select-lg {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: $custom-select-lg-font-size;
  height: $input-height-large;
}
.form-group-lg > .input-group-select,
.input-group-lg.input-group-select {
  .input-group-addon {
    padding-left: 20px;
    padding-right: 20px;
  }
  .custom-select {
    padding-left: $input-height-large;
  }
  &.input-group-select-nofill {
    .input-group-addon {
      padding-left: 0;
    }
    .custom-select {
      padding-left: 24px;
    }
  }
}


// File
//
// Custom file input.

.custom-file {
  position: relative;
  display: inline-block;
  min-width: $custom-file-width;
  max-width: 100%;
  height: $custom-file-height;
  margin-bottom: 0;
  font-weight: normal;
  font-size: $element-font-size-lg;
}

.custom-file-input {
  min-width: 0;
  max-width: $custom-file-width;
  height: $custom-file-height;
  margin: 0;
  opacity: 0;

  &:focus ~ .custom-file-control {
    @include box-shadow($custom-file-focus-box-shadow);
  }

  &:disabled ~ .custom-file-control {
    color: $gray;
    @include opacity(.65);
  }
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: $custom-file-height;
  padding: 0 $custom-file-padding-y 0 110px;
  line-height: $custom-file-height;
  color: $custom-file-color;
  pointer-events: none;
  user-select: none;
  background-color: $custom-file-bg;
  border: $custom-file-border-width dashed $custom-file-border-color;
  border-radius: $custom-file-border-radius;
  @include text-truncate;
  @include box-shadow($custom-file-box-shadow);

  @each $lang, $text in map-get($custom-file-text, placeholder) {
    &:lang(#{$lang})::after {
      content: $text;
    }
  }

  &:not(:empty)::after {
    display: none;
  }

  &::before {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    z-index: 6;
    display: block;
    height: 30px;
    padding: $padding-small-vertical $padding-small-horizontal;
    line-height: 1;
    color: $custom-file-button-color;
    background-color: $custom-file-button-bg;
    border: $custom-file-border-width solid $custom-file-border-color;
    border-radius: $custom-file-border-radius $custom-file-border-radius;
  }

  $custom-file-icon: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM5NDc0YTkiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gIDxwYXRoIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZD0iTTIyLjQgMTQuM0gyMWMtLjQgMC0uNy40LS43Ljd2NC42YzAgLjQtLjMuNy0uNy43SDQuNGMtLjQgMC0uNy0uMy0uNy0uN1YxNWMwLS4zLS4zLS43LS43LS43SDEuNmMtLjQgMC0uNy40LS43Ljd2Ni4yYzAgMSAuOSAxLjkgMS45IDEuOWgxOC40YzEgMCAxLjktLjkgMS45LTEuOVYxNWMwLS4zLS4zLS43LS43LS43ek0xMi41IDEuMWMtLjMtLjMtLjctLjMtMSAwTDUuMyA3LjNjLS4zLjMtLjMuNyAwIDFsLjkgMWMuMy4zLjcuMyAxIDBsMi42LTIuNmMuMy0uMy44LS4xLjguM3Y5LjhjMCAuNC4zLjcuNy43aDEuM2MuNCAwIC44LS40LjgtLjdWNy4xYzAtLjUuNC0uNi44LS40bDIuNiAyLjZjLjIuMy42LjMuOSAwbDEtLjljLjMtLjMuMy0uNyAwLTFsLTYuMi02LjN6Ii8+PC9zdmc+) !default;
  @each $lang, $text in map-get($custom-file-text, button-label) {
    &:lang(#{$lang})::before {
      content: $custom-file-icon "\00a0\00a0" $text;
    }
  }
}


// Layout options
//
// By default radios, checkboxes, select, input file are `inline-block` with no additional spacing
// set. Use these optional classes to tweak the layout.

.custom-select-stacked,
.custom-file-stacked {
  display: block;
  width: 100%;
}
.custom-control-stacked {
  display: block;

  .custom-control {
    margin-bottom: $custom-control-spacer-y;

    + .custom-control {
      margin-left: 0;
    }
  }
}


// feedback
.has-success {
  .custom-control {
    // color: $state-success-text;
  }
  .custom-control-indicator {
    background-color: lighten($state-success-border, 5%);
  }
  .custom-control-input {
    &:checked ~ .custom-control-indicator {
      background-color: lighten($state-success-border, 5%);
    }
    &:focus ~ .custom-control-indicator {
      box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px $state-success-border;
    }
  }
  .custom-select {
    // color: $state-success-text;
    border-color: $state-success-border;
    &:focus {
      border-color: darken($state-success-border, 10%);
    }
  }
  .custom-file-control {
    // color: $state-success-text;
    border-color: $state-success-border;
  }
  .custom-file-input:focus ~ .custom-file-control {
    box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px $state-success-border;
  }
}
.has-warning {
  .custom-control {
    // color: $state-warning-text;
  }
  .custom-control-indicator {
    background-color: lighten($state-warning-border, 5%);
  }
  .custom-control-input {
    &:checked ~ .custom-control-indicator {
      background-color: lighten($state-warning-border, 5%);
    }
    &:focus ~ .custom-control-indicator {
      box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px $state-warning-border;
    }
  }
  .custom-select {
    // color: $state-warning-text;
    border-color: $state-warning-border;
    &:focus {
      border-color: darken($state-warning-border, 10%);
    }
  }
  .custom-file-control {
    // color: $state-warning-text;
    border-color: $state-warning-border;
  }
  .custom-file-input:focus ~ .custom-file-control {
    box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px $state-warning-border;
  }
}
.has-error {
  .custom-control {
    // color: $state-danger-text;
  }
  .custom-control-indicator {
    background-color: lighten($state-danger-border, 5%);
  }
  .custom-control-input {
    &:checked ~ .custom-control-indicator {
      background-color: lighten($state-danger-border, 5%);
    }
    &:focus ~ .custom-control-indicator {
      box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px $state-danger-border;
    }
  }
  .custom-select {
    // color: $state-danger-text;
    border-color: $state-danger-border;
    &:focus {
      border-color: darken($state-danger-border, 10%);
    }
  }
  .custom-file-control {
    // color: $state-danger-text;
    border-color: $state-danger-border;
  }
  .custom-file-input:focus ~ .custom-file-control {
    box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px $state-danger-border;
  }
}