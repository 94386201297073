.themes-list {
  > a {
    padding: 0 5px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 2em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
      content: "\f111";
    }

    @include hover-focus {
      text-decoration: none;
    }
    &.active {
      &:before {
        content: "\f192";
      }
    }
  }
}