.table {
  th {
    font-weight: 500;
  }
  > thead,
  > tfoot {
    > tr {
      > th,
      > td {
        padding-top: ($table-cell-padding + 2);
        padding-bottom: ($table-cell-padding + 2);
        font-size: $element-font-size;
        text-transform: uppercase;
        color: $gray-light;
        line-height: 1.3975;

        .custom-control {
          margin: -6px 0 -3px;
        }
      }
    }
  }

  > thead > tr {
    > th,
    > td {
      border-bottom: 1px solid $table-border-color;
    }
  }
  > tbody > tr {
    > th,
    > td {
      line-height: 1.385;
    }
    &:first-child > th,
    &:first-child > td {
      border-top: 0;
    }
    .panel > &:first-child > th,
    .panel > &:first-child > td,
    .panel > .table-responsive > &:first-child > th,
    .panel > .table-responsive > &:first-child > td {
      border-top: 1px solid $table-border-color;
    }
  }

  .bg-primary,
  .bg-inverse {
    th,
    td {
      color: #ffffff;
    }
  }

  .custom-control {
    padding-left: 1rem;
  }
  .checkbox,
  .radio {
    margin: 0;
    display: inline-block;
    vertical-align: middle;

    > label {
      min-height: $font-size-base;
      line-height: $line-height-computed;
    }
  }
  .custom-control {
    margin: -3px 0 0;
  }
}
.table > thead > tr > th {
  border-bottom-width: 1px;
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    box-shadow: 0px -1px 0px $table-border-hover, inset 0px -1px 0px $table-border-hover;
  }
}

// Fixed Layout
//
// table layout fixed

.table-fixed {
  table-layout: fixed;

  tr > th,
  tr > td {
    overflow: hidden;
  }
}