.label,
.badge {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: .2px;
  vertical-align: middle;
}

.label {
  padding: .25em .6em;
}

// remove background

.label-outline-default {
  @include label-outline-variant($label-default-bg);
}
.label-outline-primary {
  @include label-outline-variant($brand-primary);
}
.label-outline-success {
  @include label-outline-variant($brand-success);
}
.label-outline-info {
  @include label-outline-variant($brand-info);
}
.label-outline-warning {
  @include label-outline-variant($brand-warning);
}
.label-outline-danger {
  @include label-outline-variant($brand-danger);
}

// Add strip border

.label-striped-default {
  @include label-striped-variant($label-default-bg);
}
.label-striped-primary {
  @include label-striped-variant($brand-primary);
}
.label-striped-success {
  @include label-striped-variant($brand-success);
}
.label-striped-info {
  @include label-striped-variant($brand-info);
}
.label-striped-warning {
  @include label-striped-variant($brand-warning);
}
.label-striped-danger {
  @include label-striped-variant($brand-danger);
}


// badge
.badge {
  font-size: $element-font-size-sm;
  font-weight: 700;
}

.badge-floated {
  position: absolute;
  top: .5em;
  right: .5em;

  &:empty {
    margin: 0;
    padding: 0;
    top: 1em;
    right: 1em;
    display: block;
    min-width: 0;
    width: 6px;
    height: 6px;
  }

  .btn & {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(25%);

    &:empty {
      top: 1em;
      right: 1em;
      transform: translateX(0);
    }
  }

  &-bottom {
    top: auto;
    bottom: 1em;
  }
}

// Colors
// Contextual variations (linked badges get darker on :hover)

.badge-default {
  @include badge-variant($label-default-bg);
}

.badge-primary {
  @include badge-variant($brand-primary);
}

.badge-success {
  @include badge-variant($brand-success);
}

.badge-info {
  @include badge-variant($brand-info);
}

.badge-warning {
  @include badge-variant($brand-warning);
}

.badge-danger {
  @include badge-variant($brand-danger);
}