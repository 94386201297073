$thumbnail-hover-caption-margin:  $thumbnail-padding;
$thumbnail-hover-caption-text:    $gray-light !default;
$thumbnail-hover-caption-bg:      rgba(#fff, .95) !default;
$thumbnail-hover-transition-dur:  150ms;

@mixin fill($padding: $thumbnail-hover-caption-margin) {
  position: absolute;
  top: $padding;
  right: $padding;
  bottom: $padding;
  left: $padding;
}

.thumbnail {
  box-shadow: 0 1px 2px rgba(#000, .15);

  > .container > .thumbnail-label,
  > .thumbnail-label {
    top: ($thumbnail-caption-padding*2);
    right: ($thumbnail-caption-padding*2);
  }

  > .container > .thumbnail-hover,
  > .thumbnail-hover {
    > figcaption {
      @include fill(0);
    }
  }
}

// thumbnail inside panel
// remove border and padding
.thumbnail-panel {
  padding: 0;
  border-width: 0;
  border-radius: 0;
  box-shadow: none;
}

.caption-title {
  font-weight: normal;
  a {
    color: $text-color;
    &:hover {
      color: $gray-darker;
      text-decoration: none;
    }
  }
}

.thumbnail-heading {
  padding: $thumbnail-caption-padding;
  font-weight: normal;

  a {
    @include hover-focus {
      text-decoration: none;
    }
  }

  + figure,
  + img {
    margin-top: $thumbnail-caption-padding;
  }

  p,
  .form-group,
  .custom-control {
    margin-bottom: 0;
  }

  .custom-control {
    margin-right: 0;
    padding-left: 16px;
  }
  p,
  .list-inline {
    font-size: $element-font-size;
  }
  .progress {
    margin-bottom: 0;
    display: inline-block;
    width: 100px;
  }
}

.thumbnail-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: ceil(($font-size-base * 1.125));
  font-weight: normal;
  color: inherit;

  > a,
  > small,
  > .small,
  > small > a,
  > .small > a {
    color: inherit;
  }
}

.thumbnail-footer {
  padding: $thumbnail-caption-padding;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  a {
    @include hover-focus {
      text-decoration: none;
    }
  }
}

.thumbnail-hover {
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  -moz-osx-font-smoothing: grayscale;

  &::before {
    transition: all $thumbnail-hover-transition-dur linear;
  }

  &:hover {
    > figcaption {
      opacity: 1;
    }
  }
  > .thumbnail-link:focus {
    ~ figcaption {
      opacity: 1;
    }
  }

  + .thumbnail-footer {
    padding-bottom: 0;
  }

  > img {
    width: 100%;
  }

  > figcaption {
    @include fill;
    padding: $grid-gutter-width;
    background-color: $thumbnail-hover-caption-bg;
    color: $thumbnail-hover-caption-text;
    opacity: 0;
    transition: all $thumbnail-hover-transition-dur linear;

    h1, h2, h3, h4, h5, h6 {
      color: $text-color;
    }

    > .thumbnail-hover-indicator,
    > .thumbnail-hover-buttons,
    > .thumbnail-extra {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      padding: 0 $grid-gutter-width;
      line-height: 1;
      text-align: center;
      color: $thumbnail-hover-caption-text;
      transform: translateY(-15px);
    }
    > .thumbnail-hover-indicator {
      .icon-wrapper {
        background-color: $brand-danger;
        color: #fff;
      }
      .progress {
        margin: 0 auto;
        width: 60%;
      }
    }
    > .thumbnail-hover-buttons,
    > .thumbnail-extra {
      z-index: 2;
    }
    > .thumbnail-extra {
      top: auto;
      bottom: ($grid-gutter-width/2);
      text-align: left;
      transform: translateY(0);

      a {
        color: inherit;
      }
    }
  }
  > .thumbnail-link {
    @include fill;
    z-index: 1;
  }
}

.thumbnail-hover-shutter {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: -35%;
    right: -35%;
    background-color: $thumbnail-hover-caption-bg;
    transform: rotate(45deg);
    transition-delay: $thumbnail-hover-transition-dur;
  }
  > figcaption {
    background-color: transparent;
    transition-delay: 0s;
  }

  &:hover {
    &:before {
      top: -35%;
      bottom: -35%;
      transition-delay: 0s;
    }
    > figcaption {
      transition-delay: $thumbnail-hover-transition-dur;;
    }
  }
}

.thumbnail-hover-zoom {
  > figcaption {
    transform: scale(0.5);
  }
  &:hover {
    > figcaption {
      transform: scale(1);
    }
  }
}

.thumbnail-label {
  position: absolute;
  display: inline;
  top: $thumbnail-caption-padding;
  right: $thumbnail-caption-padding;
  // z-index: 3;
  cursor: default;

  .label {
    color: #fff;
    background-color: darken($gray-lighter, 15%);
  }
  a.label {
    @include hover-focus {
      color: #fff;
      background-color: $brand-primary;
    }
  }
}

.caption-title,
.thumbnail-title {
  .thumbnail-label {
    position: static;
    opacity: 0;
    transition: opacity 150ms linear;

    .label {
      vertical-align: middle;
    }

    .thumbnail:hover & {
      opacity: 1;
    }
  }
}

.thumbnail-extra {
  .list-inline {
    margin-bottom: 0
  }
  a {
    font-size: $element-font-size;

    > .fa {
      font-size: $element-font-size-lg;
    }
    &:hover {
      text-decoration: none;
    }
  }
}