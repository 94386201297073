.stats {
  @include make-row();

  .progress {
    margin-bottom: $line-height-computed/2;
    background-color: #e8e8e8;
    border-radius: 0;
  }
}

.stat-block {
  display: block;
  margin-bottom: $line-height-computed;
  padding: $line-height-computed;
  background-color: $brand-default;
  border-radius: $border-radius;
}
a.stat-block,
a.stat-title {
  color: inherit;
  @include hover-focus {
    color: inherit;
    text-decoration: none;
  }
}

.stat-title {
  margin-top: 0px;
  margin-bottom: ($line-height-computed/2);
  font-size: $font-size-base;

  > small {
    display: block;
    margin-top: .25em;
    font-size: $font-size-small;
  }
}

.stat-resume {
  font-size: 36px;
}
.stat-noted {
  font-size: $font-size-small;
  margin-bottom: 0;
}
.stat-sparkline {
  display: block;
  margin-top: -3px;
}