$nbsp: "\00a0";
$breadcrumb-font-size: .875em;
.breadcrumb {
  font-size: $breadcrumb-font-size;
  white-space: nowrap;

  > li {
    vertical-align: middle;

    > a {
      display: inline-block;
      color: $text-color;
      max-width: 120px;
      vertical-align: middle;
      @include text-truncate();


      @include hover-focus {
        color: $gray-darker;
        text-decoration: none;
      }
    }
  }
  > .current {
    display: none;

    + li::before {
      display: none;
    }
    @media (min-width: $screen-sm) {
      display: inline-block;
      margin-right: .5em;
      color: $text-muted;
      font-size: $element-font-size;
    }
  }
}

// breadcrumb wrapper
.breadcrumb-wrapper {
  margin-bottom: $line-height-computed;
  background-color: #fff;
  border: 1px solid darken($border-color, 2%);
  border-radius: $border-radius-base;
  @include clearfix();

  > .breadcrumb {
    margin-bottom: 0;
    float: left;
  }
  > .breadcrumb-actions {
    margin-bottom: 0;
    margin-left: -5px;
    padding: 0 ($breadcrumb-padding-horizontal - $padding-xs-horizontal);
    list-style: none;
    float: right;

    > li {
      display: inline-block;

      > a {
        display: block;
        padding: ($breadcrumb-padding-vertical + 1) $padding-xs-horizontal;
        font-size: $element-font-size;
        color: $text-color;

        > .fa {
          color: $breadcrumb-color;
        }

        @include hover-focus {
          background-color: rgba(#000, .05);
          text-decoration: none;
        }
      }
    }
  }
  .panel > & {
    &:first-child {
      border-width: 0 0 1px 0;
      margin-bottom: 0;
      @include border-bottom-radius(0);
    }
    &:last-child {
      border-width: 1px 0 0;
      margin-bottom: 0;
      @include border-top-radius(0);
    }
  }
}

.breadcrumb {
  > li + li:before {
    display: inline-block;
    vertical-align: middle;
  }
}

.breadcrumb-arrow {
  > li + li:before {
    content: "\f105#{$nbsp}";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.breadcrumb-dash {
  > li + li:before {
    content: "\2014#{$nbsp}";
  }
}

.breadcrumb-bull {
  > li + li:before {
    content: "\2022#{$nbsp}";
  }
}

// color inverse
.breadcrumb-inverse,
.breadcrumb-wrapper > .breadcrumb-inverse {
  > li > a {
    color: $gray-lighter;

    @include hover-focus {
      color: #fff;
    }
  }
}

// truncate the breadcrumb when more than 3 levels
.breadcrumb-truncated {
  margin-left: .5rem;

  &:before {
    content: "\f111#{$nbsp}\f111#{$nbsp}\f111";
    display: inline-block;
    vertical-align: middle;
    color: $breadcrumb-color;
    letter-spacing: 4px;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 6px;
    line-height: 1rem;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  > li:not(:nth-last-child(2)):not(:last-child):not(.current) {
    display: none;
  }
}