$dropdown-grid: ($font-size-base * 4) !default;

// make dropdown hoverable
.dropdown-hoverable {
  &:hover > .dropdown-menu {
    @media (min-width: 979px) {
      display: block;
    }
  }
  > .dropdown-toggle:focus + .dropdown-menu {
    @media (min-width: 979px) {
      display: block;
    }
  }
}

.dropdown-menu {
  padding: 8px 0;
  min-width: (3 * $dropdown-grid);
  box-shadow: $dropdown-shadow;

  > li {
    > a {
      padding-top: 6px;
      padding-bottom: 6px;

      @include hover-focus {
        .dropdown-icon {
          color: inherit;
        }
      }
    }
  }

  // alternative links for dropdown menu
  .dropdown-item {
    display: block;
    padding: 6px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines

    @include hover-focus {
      text-decoration: none;
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }
    &.active {
      &,
      &:hover,
      &:focus {
        color: $dropdown-link-active-color;
        text-decoration: none;
        outline: 0;
        background-color: $dropdown-link-active-bg;
      }
    }
  }
  .dropdown-list {
    max-height: 320px;
    overflow-y: auto;
  }

  > .dropdown-header,
  > .dropdown-title {
    font-size: $element-font-size-sm;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }
  > .dropdown-title,
  > .dropdown-footer {
    display: block;
    line-height: $line-height-base;
    white-space: nowrap; // as with > li > a
  }
  > .dropdown-title {
    padding: 6px 20px 12px;
    border-bottom: 1px solid $dropdown-divider-bg;
  }
  > .dropdown-footer {
    padding: 12px 20px 6px;
    text-align: center;
    border-top: 1px solid $dropdown-divider-bg;
  }
  > .dropdown-input {
    padding: 12px 20px 6px;
  }

  .dropdown-avatar {
    margin-top: -2px;
    margin-right: .25em;
  }
  .dropdown-label {
    position: relative;
    margin-right: .5em;
    display: inline-block;
    top: 3px;
    width: 16px;
    height: 16px;
  }
  .dropdown-icon {
    display: inline-block;
    margin-right: .5em;
    width: 1.5em;
    color: $dropdown-header-color;

    &-right {
      margin-right: 0;
      margin-left: .5em;
      float: right;
      text-align: right;
    }
  }

  // externals components
  .label,
  .badge:not(.badge-floated) {
    &:not([class*="float-"]) {
      position: relative;
      top: -1px;
    }
    &[class*="float-"] {
      margin: 2px 0;
    }
  }
  .close {
    font-size: 1rem;
  }
  .progress {
    margin-bottom: 0;
  }
  .dropdown-progress-label {
    margin-bottom: .25em;
    font-size: $element-font-size-sm;
  }

  .media-heading {
    font-size: $font-size-base;
    margin-bottom: 5px;
  }
}

.dropdown-item-highlight {
  background-color: $dropdown-link-hover-bg;
  .media-heading {
    font-weight: 500;
  }
  .media-text {
    color: $text-color;
  }
  &.active {
    .media-heading,
    .media-text {
      color: inherit;
    }
  }
}


.dropdown-menu-tiles {
  padding: 0;
  min-width: 60px;

  > li {
    > a {
      position: relative;
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: center;
    }
    .dropdown &:last-child {
      > a {
        @include border-bottom-radius($border-radius-base);
      }
    }
    .dropdup &:first-child {
      > a {
        @include border-top-radius($border-radius-base);
      }
    }
  }

  > .dropdown-title,
  > .dropdown-footer {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  > .divider {
    margin: 0;
  }
}

.dropdown-list-h {
  padding-right: ($grid-gutter-width/2);
  padding-left: ($grid-gutter-width/2);
  width: 240px;
  text-align: center;

  > .row > [class*="col-"] {
    padding: 0;

    &:nth-of-type(odd) {
      border-right: 1px solid $dropdown-divider-bg;
    }
    &:nth-of-type(1n+3) {
      border-top: 1px solid $dropdown-divider-bg;
    }
  }

  .dropdown-item {
    padding: 0;
    display: table-cell;
    width: 120px;
    height: 120px;
    vertical-align: middle;

    .icon {
      display: block;
    }
    .icon-text {
      display: block;
      padding: .75em 0 .5em;
      font-size: $font-size-small;

      &-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  &-compact {
    width: 192px;
  }
  &-compact,
  &-pad {
    .dropdown-item {
      width: 96px;
      height: 96px;
    }
  }

  &-pad {
    padding: ($grid-gutter-width/2) $grid-gutter-width;
    width: 318px;

    > .row > [class*="col-"] {
      &:nth-of-type(odd) {
        border-right: none;
      }
      &:nth-of-type(1n+3) {
        border-top: none;
      }
    }

    .dropdown-item {
      border-radius: $border-radius-base;
    }
  }
}

.dropdown-menu-checker {
  .checker {
    visibility: hidden;
    opacity: 0;
  }
  .checked {
    > a {
      color: $dropdown-link-hover-color;
      .dropdown-icon {
        color: inherit;
      }
    }
    .checker {
      visibility: visible;
      opacity: 1;
    }
  }
}

.dropdown-menu-media {
  min-width: 280px;
  max-width: 320px;

  .dropdown-list {
    max-height: 400px;
  }
  .dropdown-item {
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 1.5;
    white-space: normal;
    font-size: $element-font-size-lg;
    color: $gray-light;

    @include hover-focus {
      color: $gray-light;
    }

    + .dropdown-item {
      border-top: 1px solid $dropdown-divider-bg;
    }

    .media-heading,
    strong {
      color: $text-color;
    }
    &.active {
      .media-heading,
      .media-text,
      strong {
        color: inherit;
      }
    }
    .media-text {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    time {
      display: block;
      font-size: $element-font-size;
    }
  }
}


// Inline dropdown
.dropdown-inline {
  display: inline-block;
  vertical-align: top;
  z-index: 1;

  .dropdown-menu {
    position: relative;
    display: block;
  }
}


// Inverse
.dropdown-menu-inverse {
  background-color: $dropdown-inverse-bg;

  > .divider {
    background-color: lighten($dropdown-inverse-bg, 5%);
  }
  > .dropdown-title,
  > .dropdown-footer {
    border-color: lighten($dropdown-inverse-bg, 5%);
  }
  .close {
    color: #fff;
  }
  > .dropdown-title,
  > .dropdown-header,
  .dropdown-icon,
  .media {
    color: $gray-light;
  }
  > .dropdown-input .form-control {
    color: $dropdown-inverse-color;
    background-color: lighten($dropdown-inverse-bg, 5%);
    border-color: darken($dropdown-inverse-bg, 5%);

    &:focus {
      border-color: $input-border-focus;
    }
  }
  > li > a {
    color: $dropdown-inverse-color;

    @include hover-focus {
      color: $dropdown-inverse-color;
      background-color: darken($dropdown-inverse-bg, 5%);
    }
  }
  .dropdown-item-highlight {
    background-color: darken($dropdown-inverse-bg, 5%);
  }

  .dropdown-item {
    color: $dropdown-inverse-color;

    @include hover-focus {
      color: $dropdown-inverse-color;
      background-color: darken($dropdown-inverse-bg, 5%);
    }

    .media-heading,
    .close {
      color: $dropdown-inverse-color;
    }
  }

  &.dropdown-menu-media {
    .dropdown-item {
      border-color: lighten($dropdown-inverse-bg, 5%);

      .media-text,
      strong {
        color: $dropdown-inverse-color;
      }
    }
  }

  &.dropdown-menu-checker .checked > a {
    color: $dropdown-inverse-color;
  }

  .dropdown-list-h {
    > .row > [class*="col-"] {
      &:nth-of-type(odd) ,
      &:nth-of-type(1n+3) {
        border-color: lighten($dropdown-inverse-bg, 5%);
      }
    }
  }
}


// sizing
.dropdown-sm {
  > .dropdown-menu { min-width: (1.5 * $dropdown-grid); }
}
.dropdown-md {
  > .dropdown-menu { min-width: (4 * $dropdown-grid); }
}
.dropdown-lg {
  > .dropdown-menu { min-width: (5 * $dropdown-grid); }
}
.dropdown-mega {
  > .dropdown-menu {
    width: 100%;
    @include media-breakpoint-up("md") {
      padding: $spacer;
      min-width: 512px;
    }
  }
  &.dropdown-item {
    position: static;
  }
}

// caret float
.caret {
  &[class*="float-"] {
    margin-top: .5em;
  }
  &.float-lg-none {
    @media (min-width: $screen-md) {
      margin-top: 0;
    }
  }
  &.float-md-none {
    @media (min-width: $screen-sm) {
      margin-top: 0;
    }
  }
  &.float-sm-none {
    @media (min-width: $screen-xs) {
      margin-top: 0;
    }
  }
}


// make dropdown like bottom sheet on small screen
.dropdown-menu-responsive {
  @media (max-width: $grid-float-breakpoint-max) {
    position: fixed !important;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 -6px 12px rgba(#000,.1) !important;
  }
}