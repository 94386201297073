.navbar {
  .navbar-nav {
    > li > a > [class^="icon-"] {
      position: relative;
      top: 1px;
    }
  }
  // forms
  .navbar-collapse .navbar-nav:first-child {
    li:first-child {
      @media (max-width: $grid-float-breakpoint-max) {
        .form-inline {
          border-top-color: transparent;
        }
      }
    }
  }
  .form-inline {
    padding: 7px 10px;
    border-top: 1px solid $navbar-default-border;
    border-bottom: 1px solid $navbar-default-border;

    @media (min-width: $grid-float-breakpoint) {
      border: none;
    }

    .form-group {
      margin-bottom: 0;
    }
    .input-group-addon .fa {
      font-size: 1rem;
    }
  }
  .form-ghost {
    padding-left: 0;
    padding-right: 0;

    .form-control,
    .input-group-addon {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    .focusin {
      box-shadow: none;
    }
  }
  // navbar-nav
  .nav-tabs {
    border-bottom: 0;
    > li {
      margin-bottom: 0;
      > a {
        margin-right: 0;
      }
    }
    &:not(.nav-tabs-bordered) > li > a {
      border: 0;
    }
  }
  .navbar-nav.nav-tabs-bordered {
    > li > a {
      margin-bottom: -($nav-tabs-bordered-border-width);
    }
  }
  // avatar
  .navbar-nav > li > a .avatar {
    margin: -8px 0 -4px;
    width: 30px;
    height: auto;
  }
  .dropdown {
    .btn-link {
      @include hover-focus {
        color: $link-hover-color;
      }
    }
  }
}

.navbar-collapse-wrapper {
  @media (max-width: $grid-float-breakpoint-max) {
    margin-right: -($grid-gutter-width/2);
    margin-left: -($grid-gutter-width/2);
  }
}

.navbar-collapse.in.h-a {
  overflow-y: visible;
}

// uncollapsible block
.navbar-tools {
  @include clearfix;
  .navbar-nav {
    margin: 0;
    float: left;
    > li {
      float: left;
      > a {
        padding-top:    $navbar-padding-vertical;
        padding-bottom: $navbar-padding-vertical;
      }
    }
    .open .dropdown-menu {
      position: absolute;
      box-shadow: $dropdown-shadow;
      background-color: $dropdown-bg;

      &.dropdown-menu-inverse {
        background-color: $dropdown-inverse-bg;
      }
    }
    .open .dropdown-menu-tiles > li > a {
      padding: 16px 20px;
    }
  }

  .form-inline {
    border: 0;
    .form-group {
      display: inline-block;
      max-width: 260px;
    }
    .input-group {
      display: inline-table;
      vertical-align: middle;
    }
  }
  .form-group {
    display: inline-block;
  }
}

.navbar-title {
  display: inline-block;
  position: relative;
  padding: 0 ($grid-gutter-width/2);
  font-size: $font-size-large;
  max-width: 200px;
  line-height: $navbar-height;
  color: $navbar-default-brand-color;
  vertical-align: top;
  @include text-truncate;
}
.navbar-brand,
.navbar-title {
  margin: 0;
  font-weight: normal;

  > a {
    color: inherit;
    &:hover,
    &:focus {
      color: $navbar-default-brand-hover-color;
      text-decoration: none;
    }
  }

  .navbar-inverse & {
    color: $navbar-inverse-brand-color;
    > a:hover,
    > a:focus {
      color: $navbar-inverse-brand-hover-color;
    }
  }
}
.navbar-brand {
  @media (min-width: $grid-float-breakpoint) {
    .navbar > .container-fluid &,
    .navbar > .container & {
      margin-left: 0;
    }
  }
}
.has-logo {
  padding-top: 12px;
  padding-bottom: 12px;
}
.navbar-brand-logo {
  width: auto;
  // refer to line height computed
  height: 22px;
}
.navbar-brand + .navbar-title,
.navbar-title + .navbar-brand {
  padding-left: 0;
}

.navbar-toggle {
  .float-left & {
    margin-right: 0;
  }
}

// navbar collapse
.navbar-collapse {
  @media (max-width: $grid-float-breakpoint-max) {
    .badge-floated {
      top: 1.175em;
      right: 1em
    }
    .dropdown-list-h {
      width: 100%;
      .dropdown-item {
        width: 2%;
      }
    }
    .open .dropdown-menu-tiles,
    .open .dropdown-menu-media {
      border-bottom: 1px solid $navbar-default-border;
      .navbar-inverse & {
        border-bottom: 1px solid $navbar-inverse-border;
      }
    }
    .dropdown-menu-media,
    .dropdown-menu {
      max-width: 100%;

      &.animated {
        animation-duration: 0s;
      }
    }
  }
  @media (max-width: $grid-float-breakpoint-max) {
    .dropdown-menu-inverse {
      color: $text-color;
      .dropdown-item {
        color: $gray-light;
        @include hover-focus {
          background-color: darken($navbar-default-bg, 2%);
        }
      }
      > .dropdown-title,
      > .dropdown-footer {
        border-color: $navbar-default-border;
      }
      .divider {
        background-color: $navbar-default-border;
      }

      &.dropdown-menu-media {
        .dropdown-item {
          + .dropdown-item {
            border-color: $navbar-default-border;
          }
          .media-heading,
          .media-text,
          strong {
            color: $text-color;
          }
        }
        .dropdown-item-highlight {
          background-color: $navbar-default-link-active-bg;
          @include hover-focus {
            background-color: $navbar-default-link-active-bg;
          }
          .media-text {
            color: $text-color;
          }
        }
      }

      .dropdown-list-h > .row > [class*="col-"]:nth-of-type(1n+3) {
        border-top-color: $navbar-default-border;
      }
      .dropdown-list-h > .row > [class*="col-"]:nth-of-type(odd) {
        border-right-color: $navbar-default-border;
      }
    }
  }
}

.navbar-inverse {
  .form-control {
    color: $gray-lighter;
  }
  .input-group-addon > .label {
    background-color: $navbar-inverse-bg;
  }
  .focusin .input-group-addon > .label {
    background-color: $brand-primary;
  }
  form:not(.form-ghost) {
    .form-control,
    .input-group-addon {
      background-color: rgba(#fff,.05);
      border-color: rgba(#fff,.025);
    }
    .focusin {
      .form-control,
      .input-group-addon {
        border-color: $input-border-focus;
      }
    }
  }

  // navbar collapse
  .navbar-collapse {
    @media (max-width: $grid-float-breakpoint-max) {
      .form-inline {
        border-color: $navbar-inverse-border;
      }
      .dropdown-menu {
        color: $dropdown-inverse-color;
        .dropdown-item {
          color: $gray-light;
          @include hover-focus {
            background-color: darken($navbar-inverse-bg, 2%);
          }
        }
        > .dropdown-title,
        > .dropdown-footer {
          border-color: $navbar-inverse-border;
        }
      }

      .dropdown-menu-media {
        .dropdown-item {
          + .dropdown-item {
            border-color: $navbar-inverse-border;
          }
          .media-heading,
          strong {
            color: $dropdown-inverse-color;
          }
        }
        .dropdown-item-highlight {
          background-color: $navbar-inverse-link-active-bg;
          @include hover-focus {
            background-color: $navbar-inverse-link-active-bg;
          }
          .media-text {
            color: $dropdown-inverse-color;
          }
        }
      }

      .dropdown-list-h > .row > [class*="col-"]:nth-of-type(1n+3) {
        border-top-color: $navbar-inverse-border;
      }
      .dropdown-list-h > .row > [class*="col-"]:nth-of-type(odd) {
        border-right-color: $navbar-inverse-border;
      }
    }
  }
  .navbar-btn:not([class*="btn-"]) {
    color: $dropdown-inverse-color;

    @include hover-focus {
      color: $navbar-inverse-brand-hover-color;
      background-color: $navbar-inverse-toggle-hover-bg;
    }
  }
  .form-inline {
    border-color: $navbar-inverse-border;
  }
}

.navbar-toggle {
  @include navbar-vertical-align(36px);
  padding: 10px;

  &.btn {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .icon-bar {
    width: 20px;

    + .icon-bar:not(:last-child) {
      width: 16px;
      background-color: $brand-primary;
    }
  }
}