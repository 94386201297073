// replace default style
.nav {
  > li {
    > a {
      color: $gray-light;
      &:hover,
      &:focus {
        color: $text-color;
      }
    }
  }
}
// Active state, and its :hover to override normal :hover
.nav-inline > .active > a {
  &,
  &:hover,
  &:focus {
    color: $text-color;
    // font-weight: 500;
  }
}

.panel-heading > .nav:not(.nav-pills) {
  margin: -10px 0 -16px;
}
.panel-heading > .nav-pills {
  margin: -10px 0;
}

// nav inline
.nav-inline {
  &:not(.nav-justified) > li {
    float: left;
  }
  &.nav-stacked > li {
    float: none;
  }
  > li {
    > a {
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

// right align nav items
.nav-right {
  text-align: right;

  &.nav-inline > li {
    float: none;
  }
  > li {
    float: none;
    display: inline-block;

    + li {
      margin-left: 0;
    }
  }
}

// centering nav items
.nav-centered {
  text-align: center;

  &.nav-inline > li {
    float: none;
  }
  > li {
    float: none;
    display: inline-block;

    + li {
      margin-left: 0;
    }
  }
}

// adding a bold border to tabs
.nav-tabs-bordered {
  &.nav-justified > li,
  > li {
    > a {
      margin-right: 0;
      border-width: 0 0 $nav-tabs-bordered-border-width;
      border-radius: 0;
      &:hover,
      &:focus {
        color: $text-color;
        border-color: $gray-light;
        background-color: transparent;
      }
    }
    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $text-color;
        background-color: transparent;
        border-width: 0 0 $nav-tabs-bordered-border-width;
        border-color: $link-color;
      }
    }
  }
  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      color: $text-color;
      background-color: transparent;
      border-color: $link-color;
    }
  }
  // justified
  &.nav-justified {
    > li {
      > a {
        text-align: left;
        border-width: 0 0 0 $nav-tabs-bordered-border-width;
      }
      &.active > a {
        &,
        &:hover,
        &:focus {
          border-width: 0 0 0 $nav-tabs-bordered-border-width;
        }
      }
    }
    @media (min-width: $screen-sm-min) {
      border-bottom: 1px solid $border-color;
      > li {
        > a {
          text-align: center;
          border-width: 0 0 $nav-tabs-bordered-border-width;
          border-bottom-color: transparent;
        }
        &.active > a {
          &,
          &:hover,
          &:focus {
            border-width: 0 0 $nav-tabs-bordered-border-width;
          }
        }
      }
    }
  }
}

// stacked
.nav-tabs.nav-stacked {
  border-bottom: none;

  .panel &:not(.nav-tabs-bordered) {
    padding: 0;
    > li:first-child > a {
      border-top-color: transparent;
    }
    > li:last-child > a {
      border-bottom-color: transparent;
    }
  }
  &:not(.nav-tabs-bordered) > li {
    + li {
      margin-top: -1px;
    }
  }
  > li {
    + li {
      margin-bottom: 0;
      margin-left: 0;
    }
    > a {
      margin-right: 0;
      border-width: 1px;
      border-color: $border-color transparent;
      border-radius: 0;
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
        border-color: $border-color transparent;
      }
    }
  }

  &.nav-tabs-bordered {
    > li {
      > a {
        border-width: 0 0 0 $nav-tabs-bordered-border-width;
        &:hover {
          border-color: $border-color;
        }
      }
      &.active > a {
        &,
        &:hover,
        &:focus {
          border-width: 0 0 0 $nav-tabs-bordered-border-width;
          border-color: $link-color;
        }
      }
    }
  }
}

// Tabs on panel
.panel .nav-tabs {
  // remove active background
  > li.active > a {
    &,
    &:hover,
    &:focus {
      background-color: $panel-bg;
    }
  }
}

.panel-heading > .nav-tabs {
  &[class*="float-"] {
    border-color: transparent;
  }
}


// Pills on panels
.panel:not(.panel-body) > .nav-stacked {
  > li > a {
    border-radius: 0;
  }
}


// tabs and pills
.panel-body > .nav {
  margin-bottom: $panel-body-padding;
}
.panel > .nav-tabs,
.panel > .nav-pills {
  &:first-child:not(.nav-stacked) {
    > li:first-child > a {
      border-top-left-radius: $border-radius-base;
    }
    > li:last-child > a {
      border-top-right-radius: $border-radius-base;
    }
  }

  &.nav-right:first-child:not(.nav-stacked) {
    > li:first-child > a {
      border-top-left-radius: 0;
      border-top-right-radius: $border-radius-base;
    }
    > li:last-child > a {
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: 0;
    }
  }
}
// contexts

// nav pills
.panel-heading > .nav-pills {
  border-bottom: none;
}

.panel-primary,
.panel-success,
.panel-info,
.panel-warning,
.panel-danger {
  .panel-heading + .panel-body {
    padding-top: $panel-body-padding;
  }
}


// Inverse color
.nav-inverse {
  > li {
    > a {
      color: $gray-lighter;

      &:hover,
      &:focus {
        color: $gray-lighter;
      }
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
  > .open > a {
    &:hover,
    &:focus,
    &:active {
      background-color: inherit;
    }
  }
}

.nav-tabs.nav-inverse:not(.nav-tabs-bordered) {
  > li > a,
  > .open > a {
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(#fff, .1);
      border-color: rgba(#fff, .05);
    }
  }
  > .active > a {
    &,
    &:hover,
    &:focus {
      color: $text-color;
      background-color: #ffffff;
    }
  }
}

.nav-pills.nav-inverse {
  > li > a,
  > .active > a,
  > .open > a {
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(#fff, .15);
    }
  }
  > .active > a {
    background-color: rgba(#fff, .15);
  }
}

// responsive navs
.nav {
  @media (max-width: $grid-float-breakpoint) {
    &.nav-responsive {
      > li {
        float: none;
        margin-bottom: 0;
      }

      &.nav-tabs > li {
        &.active > a,
        > a {
          &,
          &:hover,
          &:focus {
            border-width: 0 0 0 $nav-tabs-bordered-border-width;
          }
        }
      }
    }
  }
}