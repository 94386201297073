// sweet-alert vision theme
.sweet-alert.vision {
  $header-height: 56px;
  $footer-height: 65px;
  $text-color: $text-color;
  $padding: $modal-inner-padding;

  font-family: inherit;
  padding: $padding;
  padding-top: $header-height + $padding;
  border-radius: 6px;
  box-shadow: 0 2px 20px rgba(black, .3);

  // header
  h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    line-height: $header-height;
    font-size: $font-size-large;
    font-weight: 400;
    margin: 0;
    color: $text-color;
    text-align: center;
    border-bottom: 1px solid $border-color;
  }

  // body
  p {
    display: block;
    text-align: center;
    font-weight: normal;
    font-size: 1rem;
    color: $text-color !important;

    &:empty {
      display: none !important;

      ~ .sa-button-container {
        border-top: none;
        margin-top: -$padding;
      }
    }
  }

  .form-group {
    margin-bottom: 30px;

    + .sa-button-container {
      border-top: 1px solid $border-color !important;
    }
  }

  // footer
  .sa-button-container {
    margin: 0 (-$padding) (-$padding) (-$padding);
    padding: $padding;
    background-color: $body-bg;
    border-top: 1px solid $border-color;
    text-align: right;

    @media (max-width: $screen-xs-max) {
      > .btn {
        margin-bottom: 12px;
      }
    }
  }

  .sa-confirm-button-container {
    @media (max-width: $screen-xs-max) {
      display: block;
      width: 100%;
    }
  }
  .btn.btn-lg {
    @media (min-width: $screen-sm) {
      @include button-size($padding-base-vertical, $padding-base-horizontal, $element-font-size, $line-height-base, $btn-border-radius-base);
    }

    @media (max-width: $screen-xs-max) {
      display: block;
      width: 100%;
    }
  }

  // indicator
  .sa-icon:not(.sa-custom) {
    transform: scale(0.75);
    margin-top: -10px;
  }
  .sa-icon.sa-info:after {
    left: 50%;
  }
}
// Animations
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@include keyframes(animateErrorIcon) {
  0% {
    transform: rotateX(100deg), scale(0.5);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg), scale(0.5);
    opacity: 1;
  }
}
