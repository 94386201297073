.progress {
  font-size: $element-font-size-lg;
  line-height: $line-height-computed;
  box-shadow: none;
  background-color: $brand-default;

  .progress-bar {
    font-size: inherit;
    line-height: inherit;
  }

  // alternative style
  &-outline {
    padding: 1px;
    border: 2px solid $gray-lighter;
    font-size: $element-font-size;
    line-height: 16px;
    background-color: transparent;
    box-shadow: none;

    .progress-bar {
      border-radius: $progress-border-radius;
    }
  }

  &-pill {
    border-radius: $line-height-computed;

    .progress-bar {
      border-radius: $line-height-computed;
    }
  }

  &-stacked {
    .progress-bar {
      &:not(:first-child):not(:last-child) {
        border-radius: 0;
      }
      &:first-child {
        @include border-right-radius(0);
      }
      &:last-child {
        @include border-left-radius(0);
      }
    }
  }

  // variant sizes
  &-lg {
    height: 29px;
    line-height: 29px;

    &.progress-outline {
      line-height: 23px;
      padding: 3px;
    }
  }
  &-md {
    height: 16px;
    line-height: 16px;

    &.progress-outline {
      line-height: 12px;
      font-size: $element-font-size-sm;
    }
  }
  &-sm {
    height: 12px;
    font-size: $element-font-size;
    line-height: 12px;

    &.progress-outline {
      font-size: $element-font-size-sm;
      line-height: 6px;
      border-width: 1px;
    }
  }
  &-xs {
    height: 8px;
    font-size: $element-font-size-sm;
    line-height: 8px;

    &.progress-outline {
      line-height: 2px;
      border-width: 1px;
    }
  }
  &-mini {
    height: 4px;
    font-size: $element-font-size-sm;
    line-height: 4px;

    &.progress-outline {
      padding: 0;
      line-height: 2px;
    }
  }
}


// outline context
.progress-outline-primary {
  border-color: $brand-primary;
}
.progress-outline-success {
  border-color: $brand-success;
}
.progress-outline-info {
  border-color: $brand-info;
}
.progress-outline-warning {
  border-color: $brand-warning;
}
.progress-outline-danger {
  border-color: $brand-danger;
}