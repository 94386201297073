// Single side border-radius
@mixin border-radius($radius: $border-radius-base) {
  border-radius: $radius;
}

@import "utilities/align";
@import "utilities/background";
@import "utilities/borders";
@import "utilities/clearfix";
@import "utilities/display";
// @import "utilities/flex";
@import "utilities/float";
@import "utilities/position";
// @import "utilities/screenreaders";
@import "utilities/sizing";
@import "utilities/spacing";
@import "utilities/text";
@import "utilities/visibility";


// reset outline color
//
// #

a {
  &:focus,
  &:active {
    outline-color: transparent;
  }
}
select,
textarea,
input[type="checkbox"],
input[type="radio"],
[tabindex="0"],
.dropdown-menu a,
.nav a,
.navbar a,
.pagination a,
.pager a,
.btn-group,
.btn,
a.icon-wrapper,
button.list-group-item,
.progress-list button {
  &.focus,
  &:focus,
  &:active {
    outline-color: $brand-primary !important;
  }
}


// display
.hide {
  display: none;
}


// backgrounds color
//
// #

@include bg-variant('.bg-gray-darker', $gray-darker);
@include bg-variant('.bg-gray-dark', $gray-dark);
@include bg-variant('.bg-gray', $gray);
@include bg-variant('.bg-gray-light', $gray-light);
@include bg-variant('.bg-gray-lighter', $gray-lighter);
@include bg-variant('.bg-white', #fff);

// Font color &size
//
// #

@include text-emphasis-variant('.text-link', $link-color);
@include text-emphasis-variant('.text-gray-darker', $gray-darker);
@include text-emphasis-variant('.text-gray-dark', $gray-dark);
@include text-emphasis-variant('.text-gray', $gray);
@include text-emphasis-variant('.text-gray-light', $gray-light);
@include text-emphasis-variant('.text-gray-lighter', $gray-lighter);

.text-sm {
  font-size: .85em !important;
}
.text-normal {
  font-size: 1em !important;
}
.text-lg {
  font-size: 1.25em !important;
}
.text-xl {
  font-size: 1.7em !important;
}

// Socials color
//
// #

.bg-twitter {
  background-color: #1da1f2 !important;
}
.text-twitter {
  color: #1da1f2 !important;
}
.bg-facebook {
  background-color: #3b5998 !important;
}
.text-facebook {
  color: #3b5998 !important;
}
.bg-google-plus {
  background-color: #dd4b39 !important;
}
.text-google-plus {
  color: #dd4b39 !important;
}


// states helper classes
//
// #

.state-default {
  color: $text-color;
  background-color: $body-bg !important;
}
.state-active,
.state-selected {
  color: $component-active-color;
  background-color: $component-active-bg !important;
  border-color: darken($component-active-bg, 5%);
}
tr.state-active,
tr.state-selected {
  > td {
    border-color: darken($component-active-bg, 5%) !important;
  }
}
.state-success {
  color: $state-success-text;
  background-color: $state-success-bg !important;
}
.state-info {
  color: $state-info-text;
  background-color: $state-info-bg !important;
}
.state-warning {
  color: $state-warning-text;
  background-color: $state-warning-bg !important;
}
.state-danger {
  color: $state-danger-text;
  background-color: $state-danger-bg !important;
}


// borders
//
// #

.bordered {
  border: 1px solid darken($border-color, 2%) !important;
}
.bordered-top {
  border-top: 1px solid darken($border-color, 2%) !important;
}
.bordered-right {
  border-right: 1px solid darken($border-color, 2%) !important;
}
.bordered-bottom {
  border-bottom: 1px solid darken($border-color, 2%) !important;
}
.bordered-left {
  border-left: 1px solid darken($border-color, 2%) !important;
}

.border-default {
  border-color: $border-color !important;
}
.border-faded {
  border-color: darken($border-color, 2%) !important;
}
.border-gray-darker {
  border-color: $gray-darker !important;
}
.border-gray-dark {
  border-color: $gray-dark !important;
}
.border-gray {
  border-color: $gray !important;
}
.border-gray-light {
  border-color: $gray-light !important;
}
.border-gray-lighter {
  border-color: $gray-lighter !important;
}
.border-primary {
  border-color: $brand-primary !important;
}
.border-success {
  border-color: $brand-success !important;
}
.border-info {
  border-color: $brand-info !important;
}
.border-warning {
  border-color: $brand-warning !important;
}
.border-danger {
  border-color: $brand-danger !important;
}
.border-inverse {
  border-color: $brand-inverse !important;
}


// overflow
//
// #

.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-scroll {
  overflow: scroll !important;
}

// x overflow
//
// #

.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}

// y overflow
//
// #

.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}


// Cursors
//
// #

.cursor-alias {
  cursor: alias !important;
}
.cursor-all-scroll {
  cursor: all-scroll !important;
}
.cursor-auto {
  cursor: auto !important;
}
.cursor-cell {
  cursor: cell !important;
}
.cursor-context-menu {
  cursor: context-menu !important;
}
.cursor-col-resize {
  cursor: col-resize !important;
}
.cursor-copy {
  cursor: copy !important;
}
.cursor-crosshair {
  cursor: crosshair !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-e-resize {
  cursor: e-resize !important;
}
.cursor-ew-resize {
  cursor: ew-resize !important;
}
.cursor-grab {
  cursor: grab !important;
}
.cursor-grabbing {
  cursor: grabbing !important;
}
.cursor-help {
  cursor: help !important;
}
.cursor-move {
  cursor: move !important;
}
.cursor-n-resize {
  cursor: n-resize !important;
}
.cursor-ne-resize {
  cursor: ne-resize !important;
}
.cursor-nesw-resize {
  cursor: nesw-resize !important;
}
.cursor-ns-resize {
  cursor: ns-resize !important;
}
.cursor-nw-resize {
  cursor: nw-resize !important;
}
.cursor-nwse-resize {
  cursor: nwse-resize !important;
}
.cursor-no-drop {
  cursor: no-drop !important;
}
.cursor-none {
  cursor: none !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-progress {
  cursor: progress !important;
}
.cursor-row-resize {
  cursor: row-resize !important;
}
.cursor-s-resize {
  cursor: s-resize !important;
}
.cursor-se-resize {
  cursor: se-resize !important;
}
.cursor-sw-resize {
  cursor: sw-resize !important;
}
.cursor-text {
  cursor: text !important;
}
.cursor-vertical-text {
  cursor: vertical-text !important;
}
.cursor-w-resize {
  cursor: w-resize !important;
}
.cursor-wait {
  cursor: wait !important;
}
.cursor-zoom-in {
  cursor: zoom-in !important;
}
.cursor-zoom-out {
  cursor: zoom-out !important;
}
.cursor-initial {
  cursor: initial !important;
}
