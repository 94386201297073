.selectable-item {
  .selectable-indicator {
    float: right;
    visibility: hidden;
    opacity: 0;
    user-select: none;
  }

  &.selected {
    .selectable-indicator {
      visibility: visible;
      opacity: 1;
    }
  }
}