//
// Forms Layout
// --------------------------------------------------

fieldset {
  margin-top: $grid-gutter-width;
}

legend {
  margin: $grid-gutter-width 0 ($grid-gutter-width/4);
  font-size: $element-font-size;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: none;
}

label {
  font-weight: normal;
}
optgroup {
  font-weight: 500;
}

.checkbox,
.radio {
  margin-top: 0;
  font-size: $element-font-size-lg;
}

.form-group.dropdown,
.input-group.dropdown {
  .navbar-collapse &.focusin .dropdown-menu {
    @media (max-width: $grid-float-breakpoint-max) {
      border: solid $brand-primary;
      border-width: 0 1px 1px;
    }
  }
  .dropdown-menu {
    width: 100%;

    .navbar-collapse & {
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: -2px;
        margin-bottom: ($grid-gutter-width/2);
        @include border-top-radius(0);
        position: absolute;
        background-color: $dropdown-bg;
      }
    }
    .navbar-inverse .navbar-collapse & {
      @media (max-width: $grid-float-breakpoint-max) {
        background-color: tint($navbar-inverse-bg, 5%);
      }
    }
  }
}

.form-compound .form-group {
  margin-right: -($grid-gutter-width/2);
  margin-left: -($grid-gutter-width/2);
  @include clearfix();

  @media (max-width: $grid-float-breakpoint-max) {
    > [class^="col-"] + [class^="col-"] {
      margin-top: ($line-height-computed/2);
    }
  }
}

.has-clearable {
  position: relative;

  > .btn-clearable {
    display: none;
    padding: 10px;
    position: absolute;
    top: -1px;
    right: 0;
    font-size: 1rem;
    line-height: 1;
    z-index: 4;
  }

  .form-control {
    padding-right: 30px;

    &.input-lg + .btn-clearable {
      top: 4px;
      right: 6px;
    }
    &.input-sm + .btn-clearable {
      top: 0;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: $element-font-size;
    }
  }
  &.input-group-lg .btn-clearable {
    top: 4px;
    right: 6px;
  }
  &.input-group-sm .btn-clearable {
    top: 0;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: $element-font-size;
  }
}

// form as panel children
.panel > form {
  &:first-child > .form-section-heading:first-child {
    @include border-top-radius($border-radius-base);
  }

  .form-section-heading {
    border-bottom: 1px solid darken($border-color, 2%);
    border-top: 1px solid darken($border-color, 2%);

    &:first-child {
      border-top: 0;
      margin-top: 0;
    }
  }

  legend,
  .form-group,
  .form-actions {
    padding-right: $panel-body-padding;
    padding-left: $panel-body-padding;
  }
  .form-actions:last-child {
    @include border-bottom-radius($border-radius-base);
  }

  &.form-inline {
    @media (min-width: $screen-sm) {
      padding: $panel-body-padding $panel-body-padding 0;

      .custom-control {
        margin: 0;
      }
      .control-label,
      .form-group {
        margin-right: .5rem;
      }
      .form-group {
        margin-bottom: $panel-body-padding;
        padding: 0;
      }
      .form-actions {
        margin-bottom: $panel-body-padding;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        border: none;
      }
    }
  }
}

//
// Forms
// --------------------------------------------------

.control-label {
  font-size: $element-font-size-sm;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: .5em;

  > abbr {
    color: $state-danger-text;
    border-bottom: none;
  }
}

.help-block {
  font-size: $element-font-size-lg;
  color: $gray;
}

.form-control {
  font-size: $element-font-size-lg;
  color: $input-color;
  border-color: $input-border;
  box-shadow: none;
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 6px $padding-base-horizontal;

  &-inline {
    display: inline-block;
    width: auto;
  }

  &.input-pill {
    border-radius: 30px;
  }
  &.input-lg {
    font-size: 16px;
    padding: 8px $padding-large-horizontal;
    line-height: $line-height-large;
  }
  &.input-sm {
    font-size: $element-font-size;
    padding: 4px $padding-small-horizontal;
    line-height: $line-height-small;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    border-color: $input-border-focus;
    box-shadow: 0 0 8px rgba($input-border-focus, .25);
  }

  // Placeholder
  @include placeholder;

  // Disabled inputs
  &[disabled],
  fieldset[disabled] & {
    color: $gray-light;
  }

  // form control pill
  &-pill {
    border-radius: 30px;
  }
}

.form-group-select {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    right: $padding-base-horizontal;
    width: 11px;
    height: 7px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjZweCIgdmlld0JveD0iLTE1IDE3IDEwIDYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgLTE1IDE3IDEwIDYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGlkPSJwYXRoLTEiIGZpbGw9IiM0NDQ0NDQiIGQ9Ik0tMTAsMjNsLTUtNmgxMEwtMTAsMjN6Ii8+PC9zdmc+) no-repeat center center;
    background-size: 10px 6px;
    pointer-events: none;
  }

  > select.form-control {
    appearance: none;
    box-shadow: none;
    background-color: $brand-default;
  }
}

.form-actions {
  padding: $panel-body-padding 0;
  @include clearfix();

  &:last-child {
    border-top: 1px dashed darken($border-color, 5%);
  }
  &-left {
    float: left;
  }
  &-right {
    float: right;
  }

  .btn + .btn {
    margin-left: .25em;
  }
}

.form-section-heading {
  margin: 0;
  padding: $panel-body-padding;
  background-color: lighten($brand-default, 3%);
  font-size: $font-size-base;

  > .custom-control {
    margin-bottom: 0;
  }

  .collapse:not(.in) + & {
    border-top: 0;
  }
}

.form-section {
  margin: 0;
  padding: $spacer;
  .form-group:first-child {
    margin-top: $form-group-margin-bottom;
  }
  .form-group:last-child {
    margin-bottom: $form-group-margin-bottom;
  }
}

// readonly
.form-control[readonly] {
  background-color: $input-bg;
  color: $input-color;
}


// textarea
.autosize,
.no-resize {
  resize: none;
}


// sizing
.form-group-lg,
.input-group-lg {
  .form-control {
    font-size: 1rem;
  }
  .input-group-addon {
    padding: 9px $padding-large-horizontal;
    line-height: 1;
    font-size: 1rem;

    > .label {
      font-size: $element-font-size-lg;
      line-height: 1em;
    }
  }
  .input-group-btn > .btn {
    font-size: $element-font-size-lg;
  }
}
.form-group-sm,
.input-group-sm {
  .form-control {
    padding: 4px $padding-small-horizontal;
    font-size: $element-font-size;
  }
  .input-group-addon {
    padding: 4px $padding-small-horizontal;
    line-height: $line-height-small;
    font-size: $element-font-size;

    > .label {
      font-size: $element-font-size-sm;
      line-height: 1em;
    }
  }
  .input-group-btn > .btn {
    font-size: $element-font-size-sm;
  }
}