$icon-wrapper-size:        36px !default;
$icon-wrapper-size-lg:     46px !default;
$icon-wrapper-size-sm:     29px !default;
$icon-wrapper-size-xs:     22px !default;


// flag icon
// better vertical align
.flag-icon {
  vertical-align: middle;
  top: -2px;
}

// simple line stack
.fa-stack {
  .fa-stack-1x[class^="icon-"] {
    line-height: 28px;
  }
}


.icon-wrapper {
  display: inline-block;
  width: $icon-wrapper-size;
  height: $icon-wrapper-size;
  color: $gray;
  font-size: $element-font-size;
  line-height: $icon-wrapper-size;
  text-align: center;
  vertical-align: middle;
  border-radius: $border-radius-base;

  > svg {
    fill: currentColor;
    stroke-color: currentColor;
  }
  > svg,
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-.5rem, -.675rem);
    width: 1.0775em;
    height: auto;
    vertical-align: middle;
  }

  &.bg-primary,
  &.bg-info,
  &.bg-success,
  &.bg-warning,
  &.bg-danger,
  &.bg-inverse {
    color: #fff;
  }

  &-lg {
    width: $icon-wrapper-size-lg;
    height: $icon-wrapper-size-lg;
    font-size: 1rem;
    line-height: $icon-wrapper-size-lg;
    border-radius: $border-radius-large;
  }
  &-sm {
    width: $icon-wrapper-size-sm;
    height: $icon-wrapper-size-sm;
    font-size: $element-font-size;
    line-height: $icon-wrapper-size-sm;
  }
  &-xs {
    width: $icon-wrapper-size-xs;
    height: $icon-wrapper-size-xs;
    font-size: $element-font-size-sm;
    line-height: $icon-wrapper-size-xs;
  }

  &-circle {
    border-radius: $icon-wrapper-size;
  }
}

a.icon-wrapper {
  @include hover-focus {
    text-decoration: none;
  }
}
