$step-bg:                     $brand-default !default;
$step-color:                  $text-color !default;
$step-indicator-color:        $gray-light !default;
$step-item-hover-bg:          darken($brand-default, 3%) !default;

$step-padding:                $padding-base-vertical !default; // refer to button height
$step-padding-lg:             12px !default;
$step-padding-xs:             5px !default;

$step-font-size:              $element-font-size-lg !default;
$step-line-height:            20px !default; // refer to btn height
$step-line-height-lg:         22px !default; // refer to btn-lg height
$step-line-height-xs:         19px !default; // refer to btn-sm height

$step-divider-width:          (($step-padding * 2) + $step-line-height) !default;
$step-divider-color:          #fff !default;
$step-divider-border-width:   2px !default;


// step state mixin
@mixin step-state($background, $color: #fff) {
  + li:before {
    background-color: $background;
  }
  a {
    background-color: $background;
    color: $color;
  }
  .step-indicator {
    color: $background;
    border-color: $color;
    background-color: $color;
  }
  &:hover {
    + li:before {
      background-color: darken($background, 3%);
    }
    a {
      background-color: darken($background, 3%);
      color: $color;
    }
  }
}

// item length
@mixin step-max-item($max-item) {
  li {
    width: (100%/$max-item);
  }
}

// step sizing
@mixin step-size($padding, $line-height: $step-line-height) {
  $divider-width: (($padding * 2) + $step-line-height);

  li {
    &:before {
      left: -(($divider-width/2) + 3px);
      height: $divider-width;
      width: $divider-width;
    }

    a {
      padding: $padding;
      padding-left: ($divider-width/2) + 4px;
      text-align: center;
      line-height: $line-height;
    }
  }
  .step-indicator {
    width: $line-height;
    height: $line-height;
    line-height: $line-height;
  }
}


.steps {
  background-color: $step-bg;
  border-radius: $border-radius-base;
  margin-bottom: $line-height-computed;

  ul {
    margin: 0 auto;
    padding-left: 0;
    list-style: none;
    @include clearfix();
  }
  li {
    position: relative;
    float: left;
    width: (100%/3); // default have 3 item
    overflow: hidden;

    &:first-child:before {
      display: none;
    }

    &:first-child a {
      @include border-left-radius($border-radius-base);
    }
    &:last-child a {
      @include border-right-radius($border-radius-base);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -(($step-divider-width/2) + 3px);
      z-index: 1;
      display: block;
      height: $step-divider-width;
      width: $step-divider-width;
      border: 2px solid $step-divider-color;
      background-color: $step-bg;
      transform: rotate(45deg);
    }

    &:hover {
      + li:before {
        background-color: $step-item-hover-bg;
      }
      a {
        background-color: $step-item-hover-bg;
        text-decoration: none;
      }
    }
  }
  a {
    display: block;
    padding: $step-padding;
    font-size: $step-font-size;
    text-align: center;
    color: $step-color;
    @include text-truncate();

    @include hover-focus {
      text-decoration: none;
    }
  }
}

// step indicator
.step-indicator {
  margin-right: 5px;
  display: inline-block;
  width: $step-line-height;
  height: $step-line-height;
  background-color: $step-indicator-color;
  color: #fff;
  text-align: center;
  line-height: $step-line-height;
  border-radius: $step-line-height;

  &-icon {
    background-color: transparent;
    color: $gray-light;
  }
}

// steps states
.steps .active {
  @include step-state($component-active-bg);
  .step-indicator-icon {
    background-color: transparent;
    color: inherit;
  }
}
.steps .success {
  @include step-state($brand-success);
}
.steps .error {
  @include step-state($brand-danger);
}

@media (max-width: $screen-xs-max) {
  .steps li a {
    .step-indicator {
      margin-right: 0;
    }
    span:last-child {
      display: none;
    }
  }
}

@media (max-width: $screen-xs) {
  .steps li {
    &:before {
      display: none;
    }
    a {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}


// max items
.steps-item-4 {
  @include step-max-item(4);
}
.steps-item-5 {
  @include step-max-item(5);
}
.steps-item-6 {
  @include step-max-item(6);
}


// sizing
.steps-lg {
  @include step-size($step-padding-lg, $step-line-height-lg);
}

.steps-sm {
  @include step-size($step-padding-xs, $step-line-height-xs);
}