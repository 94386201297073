//
// Input groups
// --------------------------------------------------

.input-group.input-group-float {
  position: relative;
  display: block;

  .input-group-addon,
  .input-group-btn {
    padding: 0;
    display: block;
    position: absolute;
    top: 4px;
    width: auto;
    border: none;
    box-shadow: none;
    z-index: 4;

    &:first-child {
      left: 4px;
    }
    &:last-child {
      right: 4px;
    }
  }

  .form-control {
    display: block;
    float: none;

    &:first-child,
    &:last-child {
      border-left-width: 1px;
      border-right-width: 1px;
      border-radius: $border-radius-base;
    }

    &.input-lg {
      &:first-child,
      &:last-child {
        border-radius: $border-radius-large;
      }
    }
    &.input-sm {
      &:first-child,
      &:last-child {
        border-radius: $border-radius-small;
      }
    }
  }
}

.input-group-pill {
  border-radius: 30px;
}

.input-group-inline {
  display: inline-block;
}

// Custom Base styles
// -------------------------

.input-group-addon {
  padding: 6px $padding-base-horizontal;
  line-height: 1.3;
  font-size: 14px;
  color: $gray-light;
  background-color: $input-bg;
  border-color: $input-group-addon-border-color;
  letter-spacing: initial; // for label tag
  text-transform: none; // for label tag
  // @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include box-shadow(0);
  @include transition(border-color ease-in-out .15s);

  > .label {
    margin-bottom: 0;
    background-color: $gray-lighter;
    color: $gray-light;
    font-size: inherit;
    font-weight: normal;
    text-transform: none;
    cursor: default;
    line-height: 20px;

    .input-group-pill & {
      border-radius: 20px;
    }
  }
  > .checkbox,
  > .radio {
    margin: 0px -8px 0 0;
    padding-top: 4px;
    line-height: 1;
    label {
      min-height: 1em;
      padding-left: 30px;
    }
  }
  > .custom-control {
    margin: 0;
  }
  .custom-control-indicator {
    right: -2px;
    left: auto;
  }

  &:first-child > .label {
    margin-left: -.675em;
  }
  &:last-child > .label {
    margin-right: -.675em;
  }
  &:first-child {
    > .checkbox,
    > .radio {
      margin: 0px -3px 0 -16px;
      padding-top: 3px;
      padding-left: 3px;
    }
    > .custom-control {
      margin-left: -8px;
    }
    .custom-control-indicator {
      right: auto;
      left: 4px;
    }
  }
}

.input-group {
  &-pill {
    .form-control,
    .input-group-addon {
      &:first-child {
        @include border-left-radius(30px);
      }
      &:last-child {
        @include border-right-radius(30px);
      }
    }
  }

  .form-control {
    &:not(:first-child):not(:last-child) {
      border-right-width: 0;
      border-left-width: 0;
    }

    &:first-child {
      border-right-width: 0;
    }
    &:last-child {
      border-left-width: 0;
    }
  }

  .input-group-addon {
    &:first-child {
      left: 0;
      padding-right: 1px;
    }
    &:last-child {
      right: 0;
      padding-left: 1px;
    }
  }

  &.input-group-btn-pill .input-group-btn,
  &.input-group-btn-circle .input-group-btn {
    z-index: 4;
  }

  .dropdown.open {
    z-index: 5;
  }
}

.focusin {
  color: $input-color;
  box-shadow: 0 0 8px rgba($input-border-focus, .25);

  > .input-group-addon .label {
    background-color: $brand-primary;
    color: #fff;
  }

  .input-group-addon,
  .form-control {
    border-color: $input-border-focus;
  }
  .form-control {
    box-shadow: none;
  }
}

// input group elements
.input-group-addon,
.input-group-btn {
  z-index: 4;
}

.input-group-btn-circle {
  .form-control:first-child {
    padding-right: ($padding-base-horizontal * 1.5);
  }
  .form-control:last-child {
    padding-left: ($padding-base-horizontal * 1.5);
  }
}
.input-group-btn-pill {
  .form-control:first-child {
    padding-right: ($padding-base-horizontal * 2);
  }
  .form-control:last-child {
    padding-left: ($padding-base-horizontal * 2);
  }
}

.input-group-btn:first-child > .btn:last-child,
.input-group-btn:first-child > .btn-group:last-child,
.input-group-btn:first-child > .dropdown-toggle:last-child {
  margin-right: -6px;
}
.input-group-btn:first-child > .btn-pill:last-child,
.input-group-btn:first-child > .btn-group-pill:last-child {
  margin-right: -22px;
}

.input-group-btn:last-child > .btn:first-child,
.input-group-btn:last-child > .btn-group:first-child,
.input-group-btn:last-child > .dropdown-toggle:first-child {
  margin-left: -6px;
}
.input-group-btn:last-child > .btn-pill:first-child,
.input-group-btn:last-child > .btn-group-pill:first-child {
  margin-left: -22px;
}

.input-group-btn:first-child > .btn-circle,
.input-group-btn:first-child > .btn-group-circle {
  &:last-child {
    margin-right: -$padding-base-horizontal;
  }
  &.btn-sm:last-child {
    margin-right: -$padding-small-horizontal;
  }
  &.btn-lg:last-child {
    margin-right: -22px;
  }
}
.form-group-sm .input-group-btn:first-child > .btn-circle,
.form-group-sm .input-group-btn:first-child > .btn-group-circle {
  &:last-child {
    margin-right: -$padding-small-horizontal;
  }
}
.form-group-lg .input-group-btn:first-child > .btn-circle,
.form-group-lg .input-group-btn:first-child > .btn-group-circle {
  &:last-child {
    margin-right: -22px;
  }
}

.input-group-btn:last-child > .btn-circle,
.input-group-btn:last-child > .btn-group-circle {
  &:first-child {
    margin-left: -$padding-base-horizontal;
  }
  &.btn-sm:first-child {
    margin-left: -$padding-small-horizontal;
  }
  &.btn-lg:first-child {
    margin-left: -22px;
  }
}
.form-group-sm .input-group-btn:last-child > .btn-circle,
.form-group-sm .input-group-btn:last-child > .btn-group-circle {
  &:first-child {
    margin-left: -$padding-small-horizontal;
  }
}
.form-group-lg .input-group-btn:last-child > .btn-circle,
.form-group-lg .input-group-btn:last-child > .btn-group-circle {
  &:first-child {
    margin-left: -22px;
  }
}

.form-group-sm {
  .btn {
    font-size: $element-font-size-sm;
    line-height: $line-height-small;
    padding: $padding-small-vertical $padding-small-horizontal;
  }
  .btn-circle {
    padding: 0;
    width: $input-height-small;
    > .icon,
    > .fa {
      line-height: ($input-height-small - 2);
    }
  }
  .input-group-addon {
    &:first-child > .label {
      margin-left: -.675em;
    }
    &:last-child > .label {
      margin-right: -.675em;
    }
  }
}

.form-group-lg {
  .btn {
    font-size: $element-font-size-lg;
    line-height: $line-height-large;
    padding: 12px 20px;
  }
  .btn-circle {
    padding: 0;
    width: $input-height-large;
    > .icon,
    > .fa {
      line-height: ($input-height-large - 2);
    }
  }
  .input-group-addon {
    &:first-child > .label {
      margin-left: -.375em;
    }
    &:last-child > .label {
      margin-right: -.375em;
    }
  }
}