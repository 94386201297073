pre.highlight {
  padding: 0;
  line-height: 1.385;
  background-image: none;
  overflow-x: auto;

  > code {
    border-radius: inherit;
  }

}

.hljs {
  display: block;
  padding: 0.5em;
  background: $pre-bg;
  color: #333;

  &:not(.hljs-line-numbers) {
    min-width: ($container-sm - ($grid-gutter-width * 1.5));
  }
}

.hljs-comment,
.hljs-meta {
  color: #969896;
}

.hljs-string,
.hljs-variable,
.hljs-template-variable,
.hljs-strong,
.hljs-emphasis,
.hljs-quote {
  color: #df5000;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-type {
  color: #a71d5d;
}

.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-attribute {
  color: #0086b3;
}

.hljs-section,
.hljs-name {
  color: #63a35c;
}

.hljs-tag {
  color: #333333;
}

.hljs-title,
.hljs-attr,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #795da3;
}

.hljs-addition {
  color: #55a532;
  background-color: #eaffea;
}

.hljs-deletion {
  color: #bd2c00;
  background-color: #ffecec;
}

.hljs-link {
  text-decoration: underline;
}
// linenumbers
.hljs-line-numbers {
  border-color: $pre-border-color;
  color: $gray-light;
}

// dark theme
pre.highlight.highlight-dark {
  border-width: 0;

  .hljs {
    background: #222222; color: #eeeeee;
  }

  .hljs-comment,
  .hljs-template_comment,
  .hljs-javadoc {
    color: #444444;
  }

  .hljs-keyword,
  .ruby .hljs-function .hljs-keyword,
  .hljs-request,
  .hljs-status,
  .nginx .hljs-title {
    color: #C5906C;
  }

  .hljs-function .hljs-keyword,
  .hljs-sub .hljs-keyword,
  .method,
  .hljs-list .hljs-title {
    color: #C56C6C;
  }

  .hljs-string,
  .hljs-tag .hljs-value,
  .hljs-cdata,
  .hljs-filter .hljs-argument,
  .hljs-attr_selector,
  .apache .hljs-cbracket,
  .hljs-date,
  .tex .hljs-command,
  .coffeescript .hljs-attribute {
    color: #C56C6C;
  }

  .hljs-subst {
    color: #DAEFA3;
  }

  .hljs-regexp {
    color: #E9C062;
  }

  .hljs-title,
  .hljs-sub .hljs-identifier,
  .hljs-pi,
  .hljs-tag,
  .hljs-tag .hljs-keyword,
  .hljs-decorator,
  .hljs-shebang,
  .hljs-prompt {
    color: #04C4A5;
  }

  .hljs-symbol,
  .ruby .hljs-symbol .hljs-string,
  .hljs-number {
    color: #C56C6C;
  }

  .hljs-params,
  .hljs-variable,
  .clojure .hljs-attribute {
    color: #9474a9;
  }

  .css .hljs-tag,
  .hljs-rules .hljs-property,
  .hljs-pseudo,
  .tex .hljs-special {
    color: #04C4A5;
  }

  .css .hljs-class {
    color: #9474a9;
  }

  .hljs-rules .hljs-keyword {
    color: #9474a9;
  }

  .hljs-rules .hljs-value {
    color: #04C4A5;
  }

  .css .hljs-id {
    color: #8B98AB;
  }

  .hljs-annotation,
  .apache .hljs-sqbracket,
  .nginx .hljs-built_in {
    color: #9B859D;
  }

  .hljs-preprocessor,
  .hljs-pragma {
    color: #8996A8;
  }

  .hljs-hexcolor,
  .css .hljs-value .hljs-number {
    color: #DD7B3B;
  }

  .css .hljs-function {
    color: #DAD085;
  }

  .diff .hljs-header,
  .hljs-chunk,
  .tex .hljs-formula {
    background-color: #0E2231;
    color: #F8F8F8;
    font-style: italic;
  }

  .diff .hljs-change {
    background-color: #4A410D;
    color: #F8F8F8;
  }

  .hljs-addition {
    background-color: #253B22;
    color: #F8F8F8;
  }

  .hljs-deletion {
    background-color: #420E09;
    color: #F8F8F8;
  }

  .coffeescript .javascript,
  .javascript .xml,
  .tex .hljs-formula,
  .xml .javascript,
  .xml .vbscript,
  .xml .css,
  .xml .hljs-cdata {
    opacity: 0.5;
  }

  // linenumbers
  .hljs-line-numbers {
    border-color: transparent;
    color: #444444;
  }
}