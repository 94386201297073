.avatar {
  width: 46px;
  height: auto;
  border-radius: 62px;

  &-mini {
    width: 24px;
  }
  &-xs {
    width: 30px;
  }
  &-sm {
    width: 36px;
  }
  &-md {
    width: 58px;
  }
  &-lg {
    width: 80px;
  }
  &-xl {
    width: 122px;
  }
}

.avatar-square {
  border-radius: 0;
}