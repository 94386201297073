.pagination {
  font-size: $element-font-size;

  > li {
    + li > a,
    + li > span {
      margin-left: 2px;
    }
    > a,
    > span {
      text-transform: uppercase;
      font-weight: $btn-font-weight;
      letter-spacing: 1px;
      border-radius: $border-radius-base;
    }
  }

  &-pill {
    border-radius: 30px;

    > li:first-child > a,
    > li:last-child > a,
    > li > a {
      border-radius: 30px;
    }
  }

  > .first:not(.disabled) > a,
  > .first:not(.disabled) > span,
  > .last:not(.disabled) > a,
  > .last:not(.disabled) > span,
  > .prev:not(.disabled) > a,
  > .prev:not(.disabled) > span,
  > .previous:not(.disabled) > a,
  > .previous:not(.disabled) > span,
  > .next:not(.disabled) > a,
  > .next:not(.disabled) > span {
    color: $pagination-color;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border;

    @include hover-focus {
      background-color: darken($pagination-hover-bg, 5%);
      border-color: darken($pagination-hover-border, 5%);
    }
  }

  &-lg {
    > li > a,
    > li > span {
      font-size: $element-font-size-lg;
    }
  }
  &-sm {
    > li > a,
    > li > span {
      font-size: $element-font-size-sm;
    }
  }
}

// solid
.pagination-solid {
  background-color: $pagination-hover-bg;

  > li {
    + li > a,
    + li > span {
      margin-left: 0;
    }
  }

  > li > a,
  > li > span {
    @include hover-focus {
      color: $link-color;
    }
  }

  &:not(.pagination-pill) {
    > li:not(:first-child):not(:last-child) {
       > a,
       > span {
        border-radius: 0;
       }
    }
  }

  > .previous > a,
  > .previous > span,
  > .next > a,
  > .next > span {
    @include hover-focus {
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-bg;
    }
  }
}


// Pager
.pager {
  font-size: $element-font-size;
  font-weight: $btn-font-weight;
  text-transform: uppercase;
  letter-spacing: 1px;

  > li > a,
  > li > span {
    color: $pagination-color;
    padding: $padding-base-vertical $padding-base-horizontal;
  }
  > li > a {
    @include hover-focus {
      color: $pager-hover-bg;
      background-color: transparent;
      border-color: $pager-hover-bg;
    }
  }

  > .active > a {
    color: $pager-active-color;
    background-color: $pager-active-bg;

    @include hover-focus {
      color: $pager-active-color;
      background-color: $pager-active-bg;
    }
  }
}

// pagination and pager
.pagination,
.pager {

  > li > .form-control {
    display: inline-block;
    max-width: 64px;
    text-align: center;
    margin: 4px 0;
  }

  > .previous > .form-control {
    float: left;
  }
  > .next > .form-control {
    float: right;
  }

  > .previous > a > .icon {
    margin-right: .75em;
    padding-right: .75em;
    border-right: 1px solid rgba(#000, .15);
  }
  > .next > a > .icon {
    margin-left: .75em;
    padding-left: .75em;
    border-left: 1px solid rgba(#000, .15);
  }

  > .previous > a > .icon:not(.fa) {
    margin-right: 0;
  }
  > .next > a > .icon:not(.fa) {
    margin-left: 0;
  }

  &-solid {
    > li > .form-control {
      background-color: darken($pagination-hover-bg, 5%);
    }
  }
}

.pagination-lg {
  > .previous,
  > .previous + li,
  > .next {
    > .form-control {
      margin: 8px 0;
    }
  }
}
.pagination-sm {
  > .previous,
  > .previous + li,
  > .next {
    > .form-control {
      margin: 0;
    }
  }
}

// pager solid
.pager-solid {
  padding: 8px;
  background-color: $pagination-hover-bg;
  border-radius: $border-radius-base;

  > li > a {
    @include hover-focus {
      border-color: transparent;
    }
  }
}